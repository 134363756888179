import Benefits from "../../Components/Benefits/Benefits";
import FaqAccordion from "../../Components/FaqAccordion/FaqAccordion";
import HeroSection from "../../Components/HeroSection/HeroSection";
import Products from "../../Components/Products/Products";
import Reviews from "../../Components/Reviews/Reviews";
import Services from "../../Components/Services/Services";
import SmartBrands from "../../Components/SmartBrands/SmartBrands";
import "./homePage.css";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <Products />
      <Benefits />
      <SmartBrands />
      <Services />
      <Reviews />
      <FaqAccordion />
    </>
  );
};

export default HomePage;
