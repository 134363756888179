import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import mainLogo from "../../assets/mainLogo.svg";
import create_logo from "../../assets/forms/create-password.png";
import createAudio from "../../assets/forms/create-audio.png";
import "./createPassword.css";
import { useEffect, useState } from "react";
import { userUpdatePassword } from "../../utilies/api";
import { notifyError, notifySuccess } from "../ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons for showing/hiding password

const CreatePassword = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showCPassword, setShowCPassword] = useState(false); // State to toggle confirm password visibility
  const [isRestaurant, setIsRestaurant] = useState("");

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleCPasswordVisibility = () => setShowCPassword(!showCPassword);

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    try {
      if (password === cPassword) {
        const response = await userUpdatePassword({
          id: userId,
          password,
          cPassword,
          isRestaurant: isRestaurant === "admin" ? true : false,
        });
        notifySuccess(response.data.message);
        setPassword("");
        setCPassword("");
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      } else {
        notifyError("Password And Confirm Password Not Matched");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("logger");
    if (accessToken) {
      setUserId(jwtDecode(accessToken)?.record?.id);
      setIsRestaurant(jwtDecode(accessToken)?.record?.level);
    }
  }, []);

  return (
    <div className="login">
      <ToastContainer />
      <Row className="w-100 m-auto">
        <Col xs={12} md={12} lg={7} className="p-0">
          <Container className="container-relative">
            <img
              className="login-logo"
              src={mainLogo}
              alt="logo"
              onClick={() => navigate("/")}
            />
            <div className="side-form">
              <h1 className="mb-3 create-heading">Create New Password</h1>
              <p className="mb-4">
                Password: You've successfully verified your identity. Please
                enter a new password below to secure your account.
              </p>
              <form onSubmit={handleUpdatePass} className="form">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="password">
                      Password
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required={true}
                        className="input mb-3"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter Your New Password"
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="confirmPassword">
                      Confirm Password
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={cPassword}
                        onChange={(e) => setCPassword(e.target.value)}
                        required={true}
                        className="input mb-4"
                        type={showCPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm Your New Password"
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        className="password-toggle-icon"
                        onClick={toggleCPasswordVisibility}
                      >
                        {showCPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </Col>
                </Row>
                <button className="login-rest-btn back-green mb-3">Send</button>
                <div
                  className="forget-link mb-5"
                  style={{ textAlign: "center" }}
                >
                  <Link to="/login" className="forget">
                    Back to login
                  </Link>
                </div>
              </form>
            </div>
            <p className="login-footer">© 2024 SmartServe </p>
          </Container>
        </Col>
        <Col xs={0} md={0} lg={5} className="p-0">
          <div className="create-section">
            <div className="side-content">
              <img src={create_logo} alt="img" />
            </div>
            <img className="audio-create" src={createAudio} alt="logo" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreatePassword;
