import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modalComp.css";

// eslint-disable-next-line react/prop-types
function ModalComp({ show, handleClose, category, index, deleteCategory }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body
          className="modalComp-body"
          id="contained-modal-title-vcenter"
        >
          <p className="heading">
            Are you sure to Delete this{" "}
            {category[index].name ? "category" : "Item"}?
          </p>
          <p className="sub-heading">
            Delete{" "}
            {category[index].name
              ? category[index]?.name
              : category[index]?.english_name}
          </p>
        </Modal.Body>
        <Modal.Footer className="modalComp-footer">
          <Button variant="dark" onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteCategory(category[index]?.id)}
          >
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComp;
