import Chart from "chart.js/auto";
import React, { useEffect, useRef } from "react";

const PieChart = ({ fileArr, audioArr, linkArr }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "pie",
      data: {
        // labels: ["label 1", "label 2", "label 3"],
        datasets: [
          {
            data: [fileArr, audioArr, linkArr],
            backgroundColor: ["#ffd734", "#fff3c3", "#f4a223"],
          },
        ],
      },
    });
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);
  return (
    <div>
      <canvas
        ref={chartRef}
        style={{ width: "140px", height: "140px" }}
      ></canvas>
    </div>
  );
};

export default PieChart;
