import { Col, Row } from "react-bootstrap";
import left_arrow from "../../assets/RestDash/navbar/left_arrow.png";
import person from "../../assets/RestDash/person.png";
import "./restDashNavbar.css";
import { useEffect, useState } from "react";
import useNotifications from "../../hooks/useNotifications";
import { FiBell } from "react-icons/fi";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";
import { FormatDate } from "../Notifications/Notifications";

const RestDashNav = ({ title, subTitle, redirectPath, navigate }) => {
  const navigation = useNavigate();
  const [notifyBox, setNotifyBox] = useState(false);
  const rest_id = localStorage.getItem("user_id");
  const userObject = JSON.parse(localStorage.getItem("userRecord"));

  const userProfileImg = localStorage.getItem("company_profile_photo");
  const [unreadCount, setUnreadCount] = useState(0);
  const { notifications } = useNotifications(
    `https://wizard.smartserve.ai/restaurant/active/${rest_id}/notifications`
  );
  useEffect(() => {
    const unreadNotifications = notifications.filter((n) => !n.read).length;
    setUnreadCount(unreadNotifications);
  }, [notifications]);
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload);
      // Update notifications state or badge count here
      setUnreadCount((prevCount) => prevCount + 1);
      alert(`New notification: ${payload.notification.body}`);
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);
  return (
    <div className="nav-section">
      <Row className="w-100 m-auto">
        <Col xs={12} md={8} lg={7}>
          <div className="nav-heading">
            {navigate ? (
              <img
                src={left_arrow}
                alt="arrow"
                onClick={() => navigate(redirectPath)}
              />
            ) : (
              ""
            )}
            <div className="arrow-title">
              <h2>{title}</h2>
              <p>{subTitle}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} lg={5}>
          <div className="nav-group gap-4">
            <button
              onClick={() => setNotifyBox(!notifyBox)}
              type="button"
              className="btn position-relative p-0 outline-none"
            >
              <FiBell style={{ fontSize: "30px" }} />
              {!notifyBox ? (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "27px",
                    height: "27px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger"
                >
                  {unreadCount > 0 ? unreadCount : 0}
                  {/* <span className="visually-hidden">unread messages</span> */}
                </span>
              ) : null}
            </button>
            {notifyBox && notifications && (
              <div className="notify-group">
                {notifications.length > 0 ? (
                  notifications.slice(0, 4).map((notification) => (
                    <>
                      <div className="notify-box mb-3">
                        <div className="notify-title">
                          <Link
                            to={`/orderDetails/${notification?.Order?.id}`}
                            style={{ textDecoration: "underline" }}
                            className="fs-5 fw-bold"
                          >
                            #{notification?.Order?.id}
                          </Link>
                          <span
                            style={
                              notification?.Order?.orderType === "delivery"
                                ? { backgroundColor: "#415FAC" }
                                : { backgroundColor: "#129D54" }
                            }
                          >
                            {notification?.Order?.orderType}
                          </span>
                        </div>
                        {notification?.Order?.items.map((item) => (
                          // <div>
                          <p
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                            className="text-secondary mb-0"
                          >
                            {item?.qty}x {item?.english_name}
                          </p>
                          // </div>
                        ))}
                        {/* <p className="fs-6 fw-bold text-secondary">
                          1x Chicken Shawerma
                        </p>
                        <p className="fs-6 fw-bold text-secondary mb-3">
                          1x Rice ... More
                        </p> */}
                        <div className="d-flex align-items-center justify-content-end mt-3">
                          <p
                            style={{ fontSize: "13px", fontWeight: "400" }}
                            className="fw-bold text-secondary"
                          >
                            <FormatDate dateString={notification?.createdAt} />
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))
                ) : (
                  <p>No notifications</p>
                )}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigation("/notifications");
                    setNotifyBox(false);
                  }}
                  className="notifi-more"
                >
                  See All
                </span>
              </div>
            )}
            <div className="nav-dropdown">
              <img
                src={
                  userProfileImg
                    ? userProfileImg
                    : userObject.image
                    ? userObject.image
                    : person
                }
                alt="person"
              />
              <div className="paste-button mx-2">
                <b>Welcome {userObject.name} &nbsp;</b>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RestDashNav;
