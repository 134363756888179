import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/main_logo_green.png";
import flag from "../../assets/flag.svg";
import "./contactUs.css";
import GetInTouchSection from "../../Components/GetInTouchSection/GetInTouchSection";
import { useState } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { requestToJoin } from "../../utilies/api";

export const notifySuccess = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    transition: Bounce,
  });
};
export const notifyError = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    transition: Bounce,
  });
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    restaurantName: "",
    address: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  // Arrow function to handle input change
  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    // Use regex to allow only numbers
    const formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    setPhoneNumber(formattedValue);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newFormData = {
        ...formData,
        phone: phoneNumber,
      };
      const { data } = await requestToJoin(newFormData);
      notifySuccess(data.message);
      // reset the form after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        restaurantName: "",
        address: "",
      });
      setPhoneNumber("");
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <div className="contact-us">
      <Container>
        <Row>
          <ToastContainer />
          <Col xs={12} md={12} lg={5} className="mb-5">
            <GetInTouchSection
              heading_logo={logo}
              heading_title={"Contact Us"}
              main_title={"Get in Touch"}
              main_desc={`I'd love to hear about your resturant and talk to an expert and
              learn how our voice assistant can help you.`}
            />
          </Col>
          <Col xs={12} md={12} lg={7}>
            <div className="form-section">
              <div className="form-container">
                <div className="heading">
                  Fill form to get in touch with us.
                </div>
                <form onSubmit={handleSubmit} className="form">
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <input
                        required
                        className="input"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <input
                        required
                        className="input"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <input
                        required
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <div className="input d-flex">
                        <img src={flag} alt="flag" />
                        <input
                          required
                          className="custom-input"
                          type="tel"
                          name="phone"
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <input
                        required
                        className="input"
                        type="text"
                        name="restaurantName"
                        placeholder="Restaurant Name"
                        value={formData.restaurantName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <input
                        required
                        className="input"
                        type="text"
                        name="address"
                        placeholder="Restaurant Address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Col xs={12} lg={12}>
                    <input
                      required
                      className="input"
                      type="text"
                      name="jobTitle"
                      placeholder="Job Title"
                      value={formData.jobTitle}
                      onChange={handleChange}
                    />
                  </Col>
                  <input
                    className="login-button"
                    type="submit"
                    defaultValue="Submit"
                  />
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
