import React, { useEffect, useState } from "react";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { Col, Row } from "react-bootstrap";
import "./sttProfile.css";
import { getCompanyData, updateCompanyData } from "../../utilies/api";
import { notifySuccess } from "../ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import ChangeProfilePhoto from "../../Components/ChangeProfilePhoto/ChangeProfilePhoto";

const SttProfile = () => {
  // get companyId from localStorage
  const userObject = JSON.parse(localStorage.getItem("userRecord"));
  const [companyProfilePhoto, setCompanyProfilePhoto] = useState("");
  localStorage.setItem("company_profile_photo", companyProfilePhoto);
  const [profile, setProfile] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  // get profile inputs value
  const handleChange = (event) => {
    const { target } = event;
    setProfile((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  // update profile
  const handleSubmitAndRedirect = async (e) => {
    e.preventDefault();
    const data = profile;
    try {
      const response = await updateCompanyData(data);
      notifySuccess(response.message);
      setProfile(response.data);
      localStorage.setItem("sttCompanyName", response.data.name);
    } catch (error) {
      // console.log(error);
    }
  };
  // get company details
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getCompanyData(userObject.company_Id);
        setProfile({
          id: data.id,
          name: data.name,
          email: data.email,
          phone: data.phone,
          password: data.password,
        });
        setCompanyProfilePhoto(data.image);
      } catch (error) {
        console.error(error.response.data);
      }
    })();
  }, [userObject.company_Id]);

  return (
    <>
      <RestDashNav
        title={"Profile Settings"}
        subTitle={"Update Your Profile"}
        redirectPath={"/speech/signin"}
        navigate={false}
      />
      <ToastContainer />
      <div className="my-orders">
        <div className="table-container" style={{ height: "80vh" }}>
          <ChangeProfilePhoto
            id={profile?.id}
            companyProfilePhoto={companyProfilePhoto}
            imgEndPoint={"stt/auth"}
          />
          <form onSubmit={handleSubmitAndRedirect}>
            <Row className="mb-4">
              <Col xs={12} md={12} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="username"
                >
                  Full Name
                </label>
                <input
                  name="name"
                  value={profile?.name}
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="youssef ashraf"
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  readOnly={true}
                  type="email"
                  name="email"
                  value={profile?.email}
                  onChange={handleChange}
                  className="form-control"
                  id="email"
                  placeholder="youssef.ashraf@gmail.com"
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={profile?.phone}
                  onChange={handleChange}
                  className="form-control"
                  id="phone"
                  placeholder="0123456789"
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={profile?.password}
                  onChange={handleChange}
                  placeholder="********"
                />
              </Col>
            </Row>
            <Row className="p-0">
              <Col md={6} lg={10} className="p-0"></Col>
              <Col md={6} lg={2} className="p-0">
                <button type="submit" className="add-item-btn">
                  Save
                </button>
              </Col>
            </Row>
          </form>
        </div>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default SttProfile;
