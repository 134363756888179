import { NavLink, Outlet } from "react-router-dom";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import "./tabs.css";
import { useEffect, useState } from "react";
// import { useState } from "react";

const MenuAndCategoriesPage = () => {
  const [title, setTitle] = useState("Menu");
  const [subTitle, setSubTitle] = useState("Manage Your Items");
  const pathname = window.location.pathname;
  console.log(pathname);

  useEffect(() => {
    if (pathname === "/menuAndCategory/items") {
      setTitle("Menu Items");
      setSubTitle("Manage Your Items");
    } else if (pathname === "/menuAndCategory/categories") {
      setTitle("All Categories");
      setSubTitle("Manage Your Categories");
    }
  }, [pathname]);

  return (
    <>
      <RestDashNav title={title} subTitle={subTitle} redirectPath={"/login"} />
      <ul className="menus-nav">
        <NavLink
          to="Items"
          onClick={() => {
            setTitle("Menu Items");
            setSubTitle("Manage Your Items");
          }}
        >
          Menu Items
        </NavLink>
        <NavLink
          to="Categories"
          onClick={() => {
            setTitle("All Categories");
            setSubTitle("Manage Your Categories");
          }}
        >
          Categories
        </NavLink>
      </ul>
      <Outlet />
    </>
  );
};

export default MenuAndCategoriesPage;
