import { Col, Container, Row } from "react-bootstrap";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { useState } from "react";
import UserLinks from "../UserNavLinks/UserLinks";
import mainLogo from "../../assets/mainLogo.svg";

// eslint-disable-next-line react/prop-types
const Navbar = ({ logo_icon }) => {
  const navigate = useNavigate();
  const [showSideMenuBar, setShowSideMenuBar] = useState(false);
  return (
    <div className="navbar-section">
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col xs={6} sm={6} md={6} lg={2} xl={3}>
            <div className="logo">
              <img src={logo_icon ? logo_icon : mainLogo} alt="logo" />
            </div>
          </Col>
          <Col
            xs={6}
            sm={6}
            className="dots"
            onClick={() => setShowSideMenuBar(!showSideMenuBar)}
          >
            <div className="dots-icon">
              {!showSideMenuBar ? <BsThreeDotsVertical /> : <BsThreeDots />}
            </div>
            <div
              className="sidemenubar"
              style={
                showSideMenuBar ? { display: "block" } : { display: "none" }
              }
            >
              <UserLinks />
              <button onClick={() => navigate("/login")} className="login-btn">
                LOGIN
              </button>
            </div>
          </Col>
          <Col lg={8} xl={7} className="user-links">
            <UserLinks />
          </Col>
          <Col
            lg={2}
            xl={2}
            style={{ textAlign: "right" }}
            className="login-section"
          >
            <button onClick={() => navigate("/login")} className="login-btn">
              login
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navbar;
