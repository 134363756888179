import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import mainLogo from "../../../assets/white_logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./signUp.css";
import audio from "../../../assets/login-audio.png";
import axios from "axios";
import { notifyError, notifySuccess } from "../../../Pages/ContactUs/ContactUs";
import { base_url } from "../../../utilies/api";

const SignUp = () => {
  const navigate = useNavigate();
  // store inputs values
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });
  //set input values inside state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.password === formData.confirmPassword) {
        const response = await axios.post(`${base_url}/stt/auth/register`, {
          name: formData.username,
          email: formData.email,
          password: formData.password,
          cPassword: formData.confirmPassword,
          phone: formData.phone,
        });
        notifySuccess(response.data.message);
        setFormData({
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
        setTimeout(() => {
          navigate("/speech/signin");
        }, 2500);
      } else {
        notifyError("Password And Confirm Password Not Matched");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <div className="signup">
      <img
        onClick={() => navigate("/speech")}
        className="login-logo"
        src={mainLogo}
        alt="logo"
      />
      <img src={audio} alt="audio" className="audio" />
      <Row className="w-100 m-auto">
        <ToastContainer />
        <Col lg={3} xl={3}></Col>
        <Col xs={12} md={12} lg={6} xl={6} className="p-0">
          <Container className="container-relative">
            <div className="side-form signup-scroll">
              <h1 className="mb-3 text-black">Sign Up</h1>
              <p className="mb-0">
                Get started to access your restaurant management tools.
              </p>
              <form onSubmit={handleSubmit} className="form">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="email">
                      User Name
                    </label>
                    <input
                      value={formData.username}
                      onChange={handleChange}
                      autoComplete="false"
                      autoSave="false"
                      autoFocus={true}
                      required
                      className="input mb-3"
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Enter Your User Name"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      value={formData.email}
                      onChange={handleChange}
                      autoComplete="false"
                      autoSave="false"
                      autoFocus={true}
                      required
                      className="input mb-3"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email Address"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="phone">
                      Phone Number
                    </label>
                    <input
                      value={formData.phone}
                      onChange={handleChange}
                      autoComplete="false"
                      autoSave="false"
                      required
                      className="input mb-3"
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Enter Your Phone Number"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="password">
                      Password
                    </label>
                    <input
                      value={formData.password}
                      onChange={handleChange}
                      required
                      className="input mb-3"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter Your Password"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="password">
                      Confirm Password
                    </label>
                    <input
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                      className="input mb-3"
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Enter Your Confirm Password"
                    />
                  </Col>
                </Row>
                <input
                  className="login-button stt-login-btn mb-2 mt-3"
                  type="submit"
                  value="Sign Up"
                />
                <div className="forget-link">
                  <Link to="/speech/signin" className="forget mb-0">
                    Already Have An Account? Login
                  </Link>
                </div>
              </form>
            </div>
            <p className="login-footer text-white">© 2024 SmartServe </p>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
