import React from "react";
import "./errorHandler.css";

const ErrorRouteHandler = () => {
  return (
    <div className="error-page">
      <h1>Oops! Something went wrong.</h1>
      <p>
        We're sorry, but something went wrong. Please try again later or contact
        support if the issue persists.
      </p>
      <button onClick={() => window.location.reload()}>Reload Page</button>
    </div>
  );
};

export default ErrorRouteHandler;
