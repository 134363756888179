import RestDashNav from "../RestDashNav/RestDashNav";
import "./addCategory.css";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { addCategory } from "../../utilies/api";
import { notifyError, notifySuccess } from "../../Pages/ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const userToken = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  // handle add category api
  const handleAddCategory = async (token) => {
    try {
      let formData = {
        name: categoryName,
        menuId: localStorage.getItem("user_id"),
      };
      if (formData.name !== "") {
        const result = await addCategory(formData, token);
        notifySuccess(result.message);
        setTimeout(() => {
          navigate("/menuAndCategory/categories");
        }, 2000);
      } else {
        notifyError("Please Enter Category Name");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <RestDashNav
        title={"Add New Category"}
        subTitle={"Create new category to your menu"}
        redirectPath={"/menuAndCategory/categories"}
        navigate={navigate}
      />
      <div className="my-orders add-category">
        <div className="table-container">
          <ToastContainer />
          <h1>Category Name</h1>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <input
                type="text"
                className="form-control new-category"
                id="exampleFormControlInput1"
                placeholder="Enter category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="p-0 mb-3">
            <Col md={6} lg={10} className="p-0"></Col>
            <Col md={6} lg={2} className="p-0">
              <button
                type="submit"
                onClick={() => handleAddCategory(userToken)}
                className="add-item-btn mb-5"
              >
                Save
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Row className="w-100">
        <Col lg={12}>
          <div className="dashboard-footer px-4 mb-3">
            Powered by SmartServe
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AddCategory;
