import "./featureCard.css";

// eslint-disable-next-line react/prop-types
const FeatureCard = ({ card_img, card_title, card_desc }) => {
  return (
    <div className="cardd">
      {card_img ? (
        <div className="cardd-image">
          <img src={card_img} alt="img" className="w-100 h-100" />
        </div>
      ) : (
        ""
      )}
      <p className="cardd-title">{card_title}</p>
      <p className="cardd-body">{card_desc}</p>
    </div>
  );
};

export default FeatureCard;
