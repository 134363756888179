import { useNavigate, useParams } from "react-router-dom";
import RestDashNav from "../RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { editCategory, getCategoryDetails } from "../../utilies/api";
import { notifyError, notifySuccess } from "../../Pages/ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const resId = localStorage.getItem("user_id");
  const userToken = localStorage.getItem("user_token");

  // store inputs values
  const [formData, setFormData] = useState({
    id: "",
    name: "",
  });
  // update category api fun
  const editItemFun = async (token) => {
    try {
      if (formData.name !== "") {
        const result = await editCategory(formData, token);
        notifySuccess(result.message);
        navigate("/menuAndCategory/categories");
      } else {
        notifyError("Please Enter Category Name");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getCategoryDetails(id, resId, userToken);
        setFormData({
          id: data[0]?.id,
          name: data[0]?.name,
        });
      } catch (error) {}
    })();
  }, [id, resId, userToken]);
  return (
    <>
      <RestDashNav
        title={formData?.name}
        subTitle={"Edit Category"}
        redirectPath={"/menuAndCategory/categories"}
        navigate={navigate}
      />
      <div className="my-orders add-category">
        <div className="table-container">
          <ToastContainer />
          <h1>Category Name</h1>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <input
                type="text"
                className="form-control new-category"
                placeholder="Beef Burger"
                value={formData?.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row className="p-0">
            <Col md={6} lg={10} className="p-0"></Col>
            <Col md={6} lg={2} className="p-0">
              <button
                className="add-item-btn"
                onClick={() => editItemFun(userToken)}
              >
                Save
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Row className="w-100">
        <Col lg={12}>
          <div className="dashboard-footer px-4">Powered by SmartServe</div>
        </Col>
      </Row>
    </>
  );
};

export default EditCategory;
