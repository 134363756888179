import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { GetRestaurantUsers, addRestaurantUser } from "../../utilies/api";

const AddNewUser = ({ show, handleClose, setUsers, token }) => {
  const [userLoading, setUserLodaing] = useState(false);
  const [userSuccMsg, setUserSuccMsg] = useState("");
  const [userErrMsg, setUserErrMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userRole, setUserRole] = useState("manager");

  const addNewUser = async () => {
    let formData = {
      name: userName,
      phone: userPhone,
      email: userEmail,
      restaurantId: localStorage.getItem("user_id"),
      level: userRole,
    };
    try {
      const response = await addRestaurantUser(formData, token);
      setUserSuccMsg(response.message);
      setUserErrMsg("");
      setUserName("");
      setUserEmail("");
      setUserPhone("");
      setUserRole("manager");
      setTimeout(() => {
        handleClose();
        setUserSuccMsg("");
        setUserErrMsg("");
        setUserLodaing(false);
      }, 1500);
      const result = await GetRestaurantUsers(
        localStorage.getItem("user_id"),
        "",
        "",
        "",
        ""
      );
      setUsers(result.data);
    } catch (error) {
      setUserLodaing(false);
      setUserErrMsg(error.response.data.message);
      setUserSuccMsg("");
    }
  };
  useEffect(() => {}, []);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add new User</Modal.Title>
      </Modal.Header>
      {userErrMsg ? (
        <div className="alert alert-danger m-3 mb-0" role="alert">
          {userErrMsg}
        </div>
      ) : null}
      {userSuccMsg ? (
        <div className="alert alert-success m-3 mb-0" role="alert">
          {userSuccMsg}
        </div>
      ) : null}
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter user name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email address"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="number">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Mobile Number"
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="role">
            <Form.Label>Role</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              defaultValue={"Selected"}
            >
              <option disabled value={"Selected"} selected>
                Select
              </option>
              <option value="manager">Manager</option>
              <option value="employee">Employee</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            addNewUser();
            setUserLodaing(true);
          }}
        >
          {userLoading ? "Loading..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewUser;
