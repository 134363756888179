import { Col, Container, Row } from "react-bootstrap";
import back_1 from "../../assets/hero_section/back_1.png";
import back_2 from "../../assets/hero_section/back_2.png";
import back_3 from "../../assets/hero_section/back_3.png";
import logo from "../../assets/main_logo.png";
import "./heroSection.css";
import GetInTouchSection from "../GetInTouchSection/GetInTouchSection";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={5}>
            <GetInTouchSection
              heading_logo={logo}
              heading_title={"SmartServe for restaurants"}
              main_title={
                "Never Miss a Call: AI Assistant Answers & Takes Orders 24/7"
              }
              main_btn={"Get in touch"}
            />
          </Col>
          <Col xs={12} md={12} lg={7}>
            <div className="gallery">
              <figure className="card-item">
                <img src={back_1} alt="Lakeview Elegance preview" />
                <span className="first">Elevate.</span>
              </figure>
              <figure className="card-item">
                <img src={back_2} alt="Skyline Oasis preview" />
                <span className="second">Engage.</span>
              </figure>
              <figure className="card-item">
                <img src={back_3} alt="Vista Paradiso preview" />
                <span className="third">Elvove.</span>
              </figure>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
