import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/footer/logo.png";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { FaInstagram, FaSquareFacebook, FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={4}>
            <div className="footer-logo">
              <img
                className="mb-3"
                src={logo}
                alt="logo"
                onClick={() => navigate("/")}
              />
              <p>
                SmartServe offers innovative technologies like Smart Ordering
                and Smart Answering, designed to enhance efficiency, streamline
                operations, and elevate customer experiences.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <div className="footer-links">
              <ul>
                <Link to="/">HOME</Link>
                {/* <Link to="/">ABOUT MURAD</Link> */}
                <Link to="/speech">SPEECH TO TEXT</Link>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <div className="footer-links">
              <ul>
                <Link to="/aboutUs">ABOUT US</Link>
                <Link to="/contactUs">CONTACT US</Link>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <div className="social">
              <h1>social</h1>
              <div className="social-icons">
                <ul className="example-1">
                  <li className="icon-content">
                    <a
                      className="link"
                      data-social="facebook"
                      aria-label="facebook"
                      href="https://www.facebook.com/"
                    >
                      <FaSquareFacebook />
                    </a>
                    <div className="tooltip">Facebook</div>
                  </li>
                  <li className="icon-content">
                    <a
                      className="link"
                      data-social="instgram"
                      aria-label="instgram"
                      href="https://www.instgram.com/"
                    >
                      <FaInstagram />
                    </a>
                    <div className="tooltip">Instgram</div>
                  </li>
                  <li className="icon-content">
                    <a
                      className="link"
                      data-social="twitter"
                      aria-label="twitter"
                      href="https://twitter.com/"
                    >
                      <FaXTwitter />
                    </a>
                    <div className="tooltip">X</div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xxl={12}>
            <span className="d-block text-center mt-5 mb-3 copy">
              © 2024 All Rights Reserved by SmartServe Corporation.
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
