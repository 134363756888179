import React from "react";
import { Col } from "react-bootstrap";
import insert from "../../assets/speechToText/speechAudio/ic_outline-link.png";

const RecordUrl = ({ audioFile, setAudioFile }) => {
  const handleUrlChange = (event) => {
    setAudioFile({
      audio: event.target.value,
      type: "link",
    });
  };
  return (
    <Col xs={12} md={6} lg={4}>
      <div className="audio-box">
        <img src={insert} alt="audio" />
        <p>Insert Link</p>
        <input
          // readOnly={audioFile ? true : false}
          // value={audioFile.audio}
          onChange={handleUrlChange}
          type="text"
          placeholder="Paste the URL of link here"
        />
      </div>
    </Col>
  );
};

export default RecordUrl;
