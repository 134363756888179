// eslint-disable-next-line react/prop-types
const Heading = ({ logo, title }) => {
  return (
    <div className="hero-heading">
      <img src={logo} alt="logo" />
      <span>{title}</span>
    </div>
  );
};

export default Heading;
