import React, { useEffect, useState } from "react";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import edit from "../../assets/RestDash/category/edit.png";
import deleteIocn from "../../assets/RestDash/category/delete.png";
import person_1 from "../../assets/RestDash/roles/person_2.png";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import "./rolesAndUsers.css";
import PaginationComponent from "../../Components/PaginationComponent/PaginationComponent";
import AddNewUser from "../../Components/AddNewUser/AddNewUser";
import EditUser from "../../Components/EditUser/EditUser";
import { GetRestaurantUsers, deleteRestaurantUser } from "../../utilies/api";
import DeleteUser from "../../Components/DeleteUser/DeleteUser";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const RolesAndUsers = () => {
  const userToken = localStorage.getItem("user_token");
  // show delete modal
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };

  // show add user modal
  const [showUser, setShowUser] = useState(false);
  const handleShowUser = () => setShowUser(true);
  const handleCloseUser = () => setShowUser(false);

  // show edit user modal
  const [showEditUser, setShowEditUser] = useState(false);
  const [showEditId, setShowEditId] = useState(0);
  const handleShowEditUser = (idx) => {
    setShowEditUser(true);
    setShowEditId(idx);
  };
  const handleCloseEditUser = () => setShowEditUser(false);
  // get restaurant users
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [phone, setUserPhone] = useState("");
  const [status, setStatus] = useState("");
  const [level, setUserLevel] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getUsersFun = async (id, name, phone, status, level, token) => {
    try {
      const response = await GetRestaurantUsers(
        id,
        name,
        phone,
        status,
        level,
        token
      );
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const deleteUserFun = async (id) => {
    try {
      await deleteRestaurantUser(id, userToken);
      setShowId("");
      getUsersFun(
        localStorage.getItem("user_id"),
        userName,
        phone,
        status,
        level,
        userToken
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUsersFun(
      localStorage.getItem("user_id"),
      userName,
      phone,
      status,
      level,
      userToken
    );
  }, [userName, phone, status, level, userToken]);

  return (
    <>
      <RestDashNav
        title={"Roles & Users"}
        subTitle={"Manage your roles and users"}
        redirectPath={"/login"}
      />
      <div className="my-orders">
        <Row>
          <Col xs={12} md={9} lg={9} xl={8} xxl={8}>
            <h1>Total users with their roles</h1>
            <p className="mb-3">
              Find all of your company’s users accounts and their associate
              roles.
            </p>
          </Col>
          <Col xs={12} md={3} lg={3} xl={4} xxl={4}>
            <div className="search-add d-flex justify-content-end mb-4">
              <button className="login-btn" onClick={handleShowUser}>
                Add New User
              </button>
              <AddNewUser
                token={userToken}
                show={showUser}
                handleClose={handleCloseUser}
                setUsers={setUsers}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="User Name"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <input
              type="tel"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setUserPhone(e.target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <select
              className="form-select"
              defaultValue={"role"}
              onChange={(e) => setUserLevel(e.target.value)}
            >
              <option value="role" disabled>
                Select Role
              </option>
              <option value="">All</option>
              <option value="manager">Manager</option>
              <option value="employee">Employee</option>
            </select>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <select
              className="form-select"
              defaultValue={"status"}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="status" disabled>
                Select Status
              </option>
              <option value="">All</option>
              <option value="true">Active</option>
              <option value="false">In Active</option>
            </select>
          </Col>
        </Row>
        {isLoading ? (
          <div className="spinner">
            <div className="loader"></div>
          </div>
        ) : (
          <Row>
            <Col lg={12}>
              <div
                className="table-container table-responsive mt-4 text-nowrap text-capitalize"
                style={
                  users.length >= 10
                    ? { height: "auto" }
                    : {
                        height: "70vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }
                }
              >
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">User Details</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      users.map((user, idx) => {
                        return (
                          <tr key={user.id}>
                            <th scope="row">
                              <div className="menu-item-container">
                                <img src={person_1} alt="menu" />
                                <div className="menu-details">
                                  <h2>{user.name}</h2>
                                  <span>{user.email}</span>
                                </div>
                              </div>
                            </th>
                            <td>
                              <p className="fw-bold">{user.phone}</p>
                            </td>
                            <td>
                              <p className="fw-bold">{user.level}</p>
                            </td>
                            <td>
                              <p
                                style={{
                                  color: user.status ? "green" : "red",
                                  fontWeight: "bold",
                                }}
                              >
                                {user.status ? "Active" : "In Active"}
                              </p>
                            </td>
                            {user.level !== "admin" ? (
                              <td>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={edit}
                                  alt="edit"
                                  onClick={() => {
                                    handleShowEditUser(idx);
                                  }}
                                />
                                <EditUser
                                  show={showEditId === idx && showEditUser}
                                  handleClose={handleCloseEditUser}
                                  user={users}
                                  setUsers={setUsers}
                                  index={idx}
                                />
                                <img
                                  onClick={() => {
                                    handleShow(idx);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  src={deleteIocn}
                                  alt="delete_icon"
                                />
                                <DeleteUser
                                  show={showId === idx && show}
                                  handleClose={handleClose}
                                  user={users}
                                  deleteUserFun={deleteUserFun}
                                  index={idx}
                                />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <p className="empty-users" style={{ height: "50vh" }}>
                        No users found
                      </p>
                    )}
                  </tbody>
                </table>
                <PaginationComponent />
              </div>
            </Col>
            <div className="paginate my-3">
              <div className="number">
                <span>1</span>
                <span>/</span>
                <span>2</span>
              </div>
              <div className="arrows">
                <MdKeyboardArrowLeft
                  className="fs-4"
                  // onClick={handlePrevPage}
                  // style={{
                  //   cursor: hasPreviousPage ? "pointer" : "not-allowed",
                  //   opacity: hasPreviousPage ? 1 : 0.5,
                  // }}
                />
                <MdKeyboardArrowRight
                  className="fs-4"
                  // onClick={handleNextPage}
                  // style={{
                  //   cursor: hasNextPage ? "pointer" : "not-allowed",
                  //   opacity: hasNextPage ? 1 : 0.5,
                  // }}
                />
              </div>
            </div>
          </Row>
        )}
        <DashBoardFooter />
      </div>
    </>
  );
};

export default RolesAndUsers;
