import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import mainLogo from "../../../assets/white_logo.png";
import audio from "../../../assets/login-audio.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { notifyError, notifySuccess } from "../../../Pages/ContactUs/ContactUs";
import { base_url } from "../../../utilies/api";
import { jwtDecode } from "jwt-decode";

const SignIn = () => {
  const navigate = useNavigate();
  // store inputs values
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [userRecord, setUserRecord] = useState({
    company_Id: "",
    name: "",
    image: "",
  });
  if (userRecord.company_Id !== "") {
    localStorage.setItem("userRecord", JSON.stringify(userRecord));
  }
  //set input values inside state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace 'your-api-endpoint' with the actual API endpoint for user registration
      const response = await axios.post(`${base_url}/stt/auth/login`, {
        email: formData.email,
        password: formData.password,
      });
      notifySuccess(response.data.message);
      setFormData({
        email: "",
        password: "",
      });
      setUserRecord({
        company_Id: jwtDecode(response.data.token).record.id,
        name: jwtDecode(response.data.token).record.name,
        image: jwtDecode(response.data.token).record.image,
      });
      setTimeout(() => {
        navigate("/speechDashboard/dashBoard");
      }, 1500);
      localStorage.setItem("speech_token", response.data.token);
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <div className="signup">
      <img
        onClick={() => navigate("/speech")}
        className="login-logo"
        src={mainLogo}
        alt="logo"
      />
      <img src={audio} alt="audio" className="audio" />
      <Row className="w-100 m-auto">
        <ToastContainer />
        <Col lg={3} xl={3}></Col>
        <Col xs={12} md={12} lg={6} xl={6} className="p-0">
          <Container className="container-relative">
            <div className="side-form">
              <h1 className="mb-3 text-black">Login</h1>
              <p className="mb-4">
                Get started to access your restaurant management tools.
              </p>
              <form onSubmit={handleSubmit} className="form">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      value={formData.email}
                      onChange={handleChange}
                      autoComplete="false"
                      autoSave="false"
                      autoFocus={true}
                      required
                      className="input mb-3"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email Address"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="password">
                      Password
                    </label>
                    <input
                      value={formData.password}
                      onChange={handleChange}
                      required
                      className="input mb-3"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter Your Password"
                    />
                  </Col>
                  <div className="forget-link" style={{ textAlign: "right" }}>
                    <Link to="/speech/resetPassword" className="forget mb-0">
                      Forget Password?
                    </Link>
                  </div>
                </Row>
                <input
                  className="login-button stt-login-btn mb-2 mt-3"
                  type="submit"
                  value="Login"
                />
                <div className="forget-link">
                  <Link to="/speech/signup" className="forget mb-0">
                    Not Have An Account? Register
                  </Link>
                </div>
              </form>
            </div>
            <p className="login-footer text-white">© 2024 SmartServe </p>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;
