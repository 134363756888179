import { Col, Container, Row } from "react-bootstrap";
import "./speechToText.css";
import logo from "../../assets/main_logo.png";
import white_logo from "../../assets/main_logo_white.png";
import green_Logo from "../../assets/main_logo_green.png";
import blackLogo from "../../assets/main_logo_black.png";
import back_1 from "../../assets/speech/back_1.png";
import back_2 from "../../assets/speech/back_2.png";
import feature_1 from "../../assets/speech/feature_1.png";
import feature_2 from "../../assets/speech/feature_2.png";
import feature_3 from "../../assets/speech/feature_3.png";
import FeatureCard from "../../Components/FeatureCard/FeatureCard";
import Heading from "../../Components/Heading/Heading";
import IndustryCard from "../../Components/IndustryCard/IndustryCard";
import icon_1 from "../../assets/speech/icon_1.png";
import icon_2 from "../../assets/speech/icon_2.png";
import icon_3 from "../../assets/speech/icon_3.png";
import client_1 from "../../assets/speech/client_1.png";
import client_2 from "../../assets/speech/client_2.png";
import client_3 from "../../assets/speech/client_3.png";
import client_4 from "../../assets/speech/client_4.png";
import check from "../../assets/speech/check.png";
import upload from "../../assets/speech/upload.png";
import GetInTouchSection from "../../Components/GetInTouchSection/GetInTouchSection";
import SpeechSteps from "../../Components/SpeechSteps/SpeechSteps";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SpeechToText = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  return (
    <div className="speech" style={{ paddingTop: "150px" }}>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6} className="mb-5">
            <GetInTouchSection
              heading_logo={logo}
              heading_title={"SPEECH TO TEXT"}
              main_title={"Transforming Voices into Words"}
              main_desc={`Empower your ideas with precision – our seamless audio
              transcription turns voices into impactful words. Elevate your
              communication effortlessly.`}
              main_btn={"get in touch"}
            />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div className="speech-img">
              <img className="audio" src={back_2} alt="back_2" />
              <img className="img-girl" src={back_1} alt="back_1" />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="steps">
        <Container>
          <Heading logo={white_logo} title={"How It Works"} />
          <div className="step-section">
            <Row>
              <Col xs={12} md={6} lg={6}>
                {index === 0 ? (
                  <SpeechSteps
                    title={"Effortless Upload"}
                    desc={
                      "Uploading your audio files is a breeze with our intuitive platform."
                    }
                    step_num={"Step 01"}
                    index={index}
                    setIndex={setIndex}
                  />
                ) : index === 1 ? (
                  <SpeechSteps
                    title={"User-Friendly Interface"}
                    desc={
                      "Navigate seamlessly through our user-friendly interface, ensuring a smooth and hassle-free experience."
                    }
                    step_num={"Step 02"}
                    index={index}
                    setIndex={setIndex}
                  />
                ) : (
                  <SpeechSteps
                    title={"Precision Transcription"}
                    desc={
                      "Once uploaded, our advanced transcription technology takes charge, converting your audio into precise and legible text. Your words, transformed effortlessly."
                    }
                    step_num={"Step 03"}
                    index={index}
                    setIndex={setIndex}
                  />
                )}
              </Col>
              <Col xs={12} md={6} lg={6}>
                <div className="right-section">
                  {index === 0 ? (
                    <>
                      <div className="uploaded">
                        <img src={upload} alt="check" className="mb-3" />
                        <p>
                          Drags and Drop files <br /> or{" "}
                        </p>
                        <div className="audio-progress-2">
                          <div className="input-group input-groups">
                            <input
                              type="file"
                              className="form-control"
                              id="inputGroupFile02"
                            />
                            <label
                              className="input-group-text logo-text"
                              htmlFor="inputGroupFile02"
                            >
                              Browse
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/speech/signin")}
                        className="steps-btn"
                      >
                        Sign In To Upload File
                      </button>
                    </>
                  ) : index === 1 ? (
                    <>
                      <div className="uploaded">
                        <img src={check} alt="check" className="mb-3" />
                        <p>File uploaded successfully </p>
                        <div className="audio-progress">
                          <span>audioname1.mp3</span>
                          <p>100%</p>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/speech/signin")}
                        className="steps-btn"
                      >
                        Sign In To Transcribe Now
                      </button>
                    </>
                  ) : index === 2 ? (
                    <>
                      <div className="transcript">
                        <p>
                          Today, we talked about technology and how it affects
                          our lives. We discussed things like artificial
                          intelligence and its pros and cons. The panel agreed
                          that it's important to use AI responsibly. They also
                          talked about how education can help us adapt to new
                          tech changes. It was an interesting chat about the
                          impact of technology on our world
                        </p>
                      </div>
                      <button
                        onClick={() => navigate("/speech/signin")}
                        className="steps-btn"
                      >
                        Sign In To Download
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="features">
        <Container>
          <Heading logo={logo} title={"FEATURES"} />
          <Row>
            <Col xs={12} md={6} lg={4}>
              <FeatureCard
                card_img={feature_1}
                card_title={"Accurate Transcription"}
                card_desc={
                  "Our service guarantees accuracy, capturing every nuance of the spoken word."
                }
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FeatureCard
                card_img={feature_2}
                card_title={"Multiple Format Support"}
                card_desc={
                  "We accommodate various audio formats, making it convenient for our users."
                }
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FeatureCard
                card_img={feature_3}
                card_title={"Customization Options"}
                card_desc={
                  "Tailor the transcription to your specific requirements, ensuring the output aligns with your needs."
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="clients">
        <Container>
          <Row>
            <Col xs={12} md={6} lg={6} className="mb-3">
              <div className="hero-details">
                <Heading logo={green_Logo} title={"OUR CLIENTS"} />
                <h1 className="mb-0">
                  Join Our Client <br /> Community
                </h1>
                <p>
                  Discover how our audio transcription service has empowered
                  diverse clients across industries.
                </p>
              </div>
            </Col>
            <Col xs={6} md={3} lg={3}>
              <div className="clients-img">
                <img className="mb-3 w-100" src={client_2} alt="client" />
                <img
                  className="mb-3 w-100"
                  style={{ backgroundColor: "white" }}
                  src={client_1}
                  alt="client"
                />
                <img
                  className="mb-3 w-100"
                  style={{ backgroundColor: "white" }}
                  src={client_3}
                  alt="client"
                />
                <img className="w-100 mb-3" src={client_4} alt="client" />
                <img
                  className="w-100 mb-5"
                  style={{ backgroundColor: "white" }}
                  src={client_3}
                  alt="client"
                />
              </div>
            </Col>
            <Col xs={6} md={3} lg={3}>
              <div className="clients-img img-padding">
                <img
                  className="mb-3 w-100"
                  style={{ backgroundColor: "white" }}
                  src={client_3}
                  alt="client"
                />
                <img className="mb-3 w-100" src={client_2} alt="client" />
                <img
                  className="mb-3 w-100"
                  style={{ backgroundColor: "white" }}
                  src={client_1}
                  alt="client"
                />
                <img className="w-100 mb-3" src={client_4} alt="client" />
                <img
                  className="w-100"
                  style={{ backgroundColor: "white" }}
                  src={client_3}
                  alt="client"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="industry">
        <Container>
          <Heading logo={blackLogo} title={"Industries We Serve"} />
          <Row>
            <Col xs={12} md={6} lg={4}>
              <IndustryCard
                card_img={icon_1}
                card_title={"Legal"}
                card_desc={
                  "Perfect for transcribing court proceedings, depositions, and legal discussions. Our precise transcription service ensures accuracy in every legal document, streamlining processes for legal professionals."
                }
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <IndustryCard
                card_img={icon_2}
                card_title={"Business"}
                card_desc={
                  "Efficiently transcribe meetings, interviews, and conference calls to keep your business documentation organized. Our service adapts to the fast-paced business environment, delivering accurate transcripts for insightful decision-making."
                }
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <IndustryCard
                card_img={icon_3}
                card_title={"Education"}
                card_desc={
                  "Enhance the accessibility of lectures, research interviews, and educational content. Transform spoken words into written resources, making educational materials more inclusive and readily available. Welcome to a new era of transcription across diverse industries."
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SpeechToText;
