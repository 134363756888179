import { Col, Row } from "react-bootstrap";
import "./dashBoardFooter.css";

const DashBoardFooter = () => {
  return (
    <Row>
      <Col lg={12}>
        <div className="dashboard-footer">Powered by SmartServe </div>
      </Col>
    </Row>
  );
};

export default DashBoardFooter;
