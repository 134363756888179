import { Container } from "react-bootstrap";
import "./faq.css";
import logo from "../../assets/main_logo.png";
import Accordion from "../Accordion/AccordionItem";
import Heading from "../Heading/Heading";

const FaqAccordion = () => {
  return (
    <div className="faq-section">
      <Container>
        <Heading logo={logo} title={"FAQ’s"} />
        <h1 className="my-5">Find your answers</h1>
        <Accordion />
      </Container>
    </div>
  );
};

export default FaqAccordion;
