import { Button, Col, Modal, Row } from "react-bootstrap";
import RestDashNav from "../RestDashNav/RestDashNav";
import "./orderItemDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GetRestaurantOrdersDetails,
  updateRestaurantOrderStatus,
} from "../../utilies/api";
import { getDateTime } from "../MyOrders/MyOrders";
import edit_icon from "../../assets/RestDash/menu/edit_icon.png";

const OrderItemDetails = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const { id } = useParams();
  const [order, setOrder] = useState([]);

  const [changeStatus, setChangeStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getOrder = async (id) => {
    try {
      const result = await GetRestaurantOrdersDetails(id, "", "", "", "", "");
      setOrder(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const changeOrderStatusFun = async (id, status) => {
    try {
      const formData = {
        id,
        status,
      };
      await updateRestaurantOrderStatus(formData);
      getOrder(id);
      handleClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getOrder(id);
  }, [id]);

  return (
    <>
      <RestDashNav
        title={"Order Details"}
        subTitle={"Show order details"}
        redirectPath={"/myOrders"}
        navigate={navigate}
      />
      {isLoading ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="my-orders order-details">
          <div className="table-container">
            {order.length > 0 ? (
              <Row>
                <div className="first-row">
                  <div className="first-col">
                    <p>Order Number</p>
                    <h2>#{order[0]?.id}</h2>
                  </div>
                  <div className="second-col">
                    <span
                      style={
                        order[0]?.status === "completed"
                          ? { backgroundColor: "rgba(55, 179, 74, 1)" }
                          : order[0]?.status === "cancelled"
                          ? {
                              backgroundColor: "rgb(255, 52, 52)",
                            }
                          : order[0]?.status === "in progress"
                          ? { backgroundColor: "rgb(244, 162, 35)" }
                          : { backgroundColor: "rgba(55, 179, 74, 1)" }
                      }
                      className="completed"
                    >
                      {order[0]?.status}
                    </span>
                    <img
                      width={"30px"}
                      height={"30px"}
                      onClick={handleShow}
                      src={edit_icon}
                      alt="edit_icon"
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                    <Modal centered show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Update Order Status #{order[0]?.id}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Change Order Status
                        </label>
                        <select
                          className="form-select"
                          defaultValue={order[0]?.status}
                          value={changeStatus}
                          onChange={(e) => setChangeStatus(e.target.value)}
                        >
                          <option disabled>Select Order Status</option>
                          <option value="received">Received</option>
                          <option value="completed">Completed</option>
                          <option value="in progress">In Progress</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() =>
                            changeOrderStatusFun(order[0]?.id, changeStatus)
                          }
                        >
                          Update Status
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
                <div className="first-row">
                  <div className="first-col">
                    <p>Order Date</p>
                    <h2>{getDateTime(order[0]?.createdAt)}</h2>
                  </div>
                  <div className="first-col">
                    <p>Order Type</p>
                    <h2>{order[0]?.orderType}</h2>
                  </div>
                  <div className="second-col">
                    <div className="first-col">
                      <p>Payment Method</p>
                      <h2 style={{ textAlign: "right" }}>
                        {order[0]?.paymentMethod}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="first-row">
                  <div className="first-col w-100">
                    <p>Items</p>
                    {order[0]?.items.length > 0 ? (
                      order[0]?.items?.map((item) => {
                        return (
                          <div key={item.id}>
                            <div className="d-flex justify-content-between">
                              <p key={item?.id}>
                                {item?.qty}x {item?.english_name}
                              </p>
                              <p>{item?.price * item?.qty} JOD</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <span className="text-danger fw-bold">No Items</span>
                    )}
                    <h4 className="text-danger fw-bold">Notes</h4>
                    <b className="text-success">
                      {order[0]?.notes ? order[0]?.notes : "There Are No Notes"}
                    </b>
                  </div>
                </div>
                <div className="first-row">
                  <div className="first-col">
                    <p>Total Amount</p>
                  </div>
                  <div className="second-col">
                    <p
                      style={{
                        color: "rgba(55, 179, 74, 1)",
                        fontSize: "18px",
                      }}
                    >
                      {order[0]?.total} JOD
                    </p>
                  </div>
                </div>
                <div className="first-row first-row-mobile">
                  <div className="first-col first-col-mobile">
                    <p>Customer Details</p>
                    <p>Full Name</p>
                    <p>Phone Number</p>
                    <p>Address</p>
                  </div>
                  <div className="second-col">
                    <p className="p-none" style={{ visibility: "hidden" }}>
                      Items
                    </p>
                    <p className="p-mobile">{order[0]?.name}</p>
                    <p className="p-mobile">{order[0]?.customer?.phone}</p>
                    <p className="p-mobile">
                      {order[0]?.address ? order[0]?.address : "No Address"}
                    </p>
                  </div>
                </div>
              </Row>
            ) : (
              <h1
                style={{
                  height: "70vh",
                  fontSize: "25px",
                  fontWeight: "bold",
                  color: "rgba(55, 179, 74, 1)",
                }}
                className="d-flex justify-content-center align-items-center"
              >
                No Order Details For This Order
              </h1>
            )}
          </div>
        </div>
      )}

      <Row className="w-100">
        <Col lg={12}>
          <div className="dashboard-footer px-4 mb-3">
            Powered by SmartServe
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OrderItemDetails;
