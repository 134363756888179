import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TokensChartBar = () => {
  // const labels = revenue.map((data) => data.day);
  // const totals = revenue.map((data) => data.total);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Sales Analytics",
      },
      tooltip: {
        enabled: true, // Default is true, so this is optional
        mode: "index", // or 'nearest'
        intersect: false, // Ensures tooltip appears when hovering near the element
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += `$${context.raw.toFixed(2)}`; // Customize the data display
            return label;
          },
        },
      },
    },
  };

  const barChartData = {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: "Tokens",
        data: [100, 70, 80, 110, 90, 130, 50],
        backgroundColor: "#F4A223",
        borderColor: "#F4A223",
        borderWidth: 3,
        barThickness: 25,
        borderRadius: 100,
      },
    ],
  };

  return <Bar data={barChartData} options={options} className="chart" />;
};

export default TokensChartBar;
