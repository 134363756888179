import "./paginationComponent.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const PaginationComponent = ({
  currentPage,
  totalPages,
  handleNextPage,
  handlePrevPage,
  hasNextPage,
  hasPreviousPage,
}) => {
  return (
    <div className="paginate my-3">
      {totalPages > 1 && (
        <>
          <div className="number">
            <span>{currentPage}</span>
            <span>/</span>
            <span>{totalPages}</span>
          </div>
          <div className="arrows">
            <MdKeyboardArrowLeft
              className="fs-4"
              onClick={handlePrevPage}
              style={{
                cursor: hasPreviousPage ? "pointer" : "not-allowed",
                opacity: hasPreviousPage ? 1 : 0.5,
              }}
            />
            <MdKeyboardArrowRight
              className="fs-4"
              onClick={handleNextPage}
              style={{
                cursor: hasNextPage ? "pointer" : "not-allowed",
                opacity: hasNextPage ? 1 : 0.5,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaginationComponent;
