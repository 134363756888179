import React, { useRef, useState } from "react";
import RestDashNav from "../../RestDashNav/RestDashNav";
import { Col, Row, Spinner } from "react-bootstrap";
import DashBoardFooter from "../../DashBoardFooter/DashBoardFooter";
import "./speechAudio.css";
import copy from "../../../assets/speechToText/speechAudio/solar_copy-broken.png";
import RecordBox from "../../RecordBox/RecordBox";
import RecordUrl from "../../RecordBox/RecordUrl";
import axios from "axios";
import { notifyError, notifySuccess } from "../../../Pages/ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import FileAudioBox from "../../RecordBox/FileAudioBox";
import { FaCheck } from "react-icons/fa";
import { base_url } from "../../../utilies/api";

const SpeechAudio = () => {
  const [loading, setLoading] = useState(false);
  // copy text
  const textareaRef = useRef(null);
  // state to change copy icon
  const [copyIcon, setCopyIcon] = useState(false);
  // Function to copy text from textarea
  const handleCopyText = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand("copy");
    }
  };

  const [audioResult, setAudioResult] = useState(null);
  const [audioFile, setAudioFile] = useState({
    audio: "",
    type: "",
  });
  const handleTranscribe = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${base_url}/stt/audiofile`,
        { ...audioFile },
        {
          headers: {
            "Content-Type":
              audioFile.type === "link"
                ? "application/json"
                : "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("speech_token")}`,
            // Add more headers as needed
          },
        }
      );
      setAudioResult(response.data.data);
      notifySuccess(response.data.message);
      setAudioFile({
        audio: "",
        type: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <RestDashNav
        title={"Audio Transcription"}
        subTitle={"Convert spoken words or audio content into written text."}

        // navigate={"/speech/signin"}
      />
      <div className="my-orders">
        <ToastContainer />
        <h1>Import Files</h1>
        <Row>
          <RecordUrl audioFile={audioFile} setAudioFile={setAudioFile} />
          <FileAudioBox audioFile={audioFile} setAudioFile={setAudioFile} />
          <RecordBox audioFile={audioFile} setAudioFile={setAudioFile} />
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <div className="speech-heading">
              <h2>Transcription</h2>
              {copyIcon ? (
                <div>
                  <span
                    style={{ color: "green", fontWeight: "bold" }}
                    className="mx-2"
                  >
                    Copied
                  </span>
                  <FaCheck style={{ color: "green" }} />
                </div>
              ) : (
                <img
                  onClick={() => {
                    if (audioResult?.text) {
                      handleCopyText();
                      setCopyIcon(true);
                    }
                  }}
                  src={copy}
                  alt="copy"
                />
              )}
            </div>
            <textarea
              ref={textareaRef}
              readOnly
              className="audio-box w-100"
              value={audioResult?.text}
            />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="speech-heading">
              <h2>Transcription Time (s)</h2>
            </div>
            <textarea
              readOnly
              className="audio-box w-100 mb-4"
              value={audioResult?.duration && audioResult?.duration * 60}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <button
              onClick={handleTranscribe}
              disabled={audioFile.audio ? false : true}
              style={
                audioFile.audio
                  ? { backgroundColor: "#FFD734" }
                  : { backgroundColor: "#D9D9D9", cursor: "not-allowed" }
              }
              className="transcripe"
            >
              {loading ? <Spinner /> : "Transcribe"}
            </button>
          </Col>
        </Row>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default SpeechAudio;
