import React, { useState } from "react";
import { Col } from "react-bootstrap";
import audio from "../../assets/speechToText/speechAudio/mdi_microphone.png";
import filled_audio from "../../assets/speechToText/speechAudio/fluent_mic-record-24-filled.png";
import axios from "axios";
import "./record.css";
import { base_url } from "../../utilies/api";

const RecordBox = ({ setAudioFile }) => {
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        setMediaStream(stream);
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        const chunks = [];
        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: "audio/x-m4a" });
          setAudioBlob(blob);

          // Download the recorded audio file
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "recorded_audio.m4a"); // Set the filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        recorder.start();
        setRecording(true);
      })
      .catch((error) => console.error("Error accessing media devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaStream.getTracks().forEach((track) => track.stop());
      setRecording(false);
    }
  };

  const handleUpload = async () => {
    if (audioBlob) {
      const formData = new FormData();
      formData.append("audio", audioBlob, "recorded_audio.wav");
      formData.append("type", "record");

      try {
        await axios.post(`${base_url}/stt/audiofile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("speech_token")}`,
          },
        });
        // console.log("Audio uploaded:", response.data);
        // If you need to handle response data, you can do it here
      } catch (error) {
        console.error("Error uploading audio:", error);
      }
    } else {
      console.error("No audio recorded.");
    }
  };

  return (
    <Col xs={12} md={6} lg={4}>
      <div className="audio-box">
        <img src={recording ? filled_audio : audio} alt="audio" />
        <p>{recording ? "Record Now" : "Record from microphone"}</p>
        {recording ? (
          <button
            style={{ backgroundColor: "#F15623" }}
            onClick={stopRecording}
          >
            Stop Recording
          </button>
        ) : (
          <button style={{ backgroundColor: "black" }} onClick={startRecording}>
            Start Recording
          </button>
        )}
        {audioBlob && (
          <button onClick={handleUpload}>Upload Recorded Audio</button>
        )}
      </div>
    </Col>
  );
};

export default RecordBox;
