import { Col, Row } from "react-bootstrap";
import "./services.css";
import background from "../../assets/services/background.png";
import icon_1 from "../../assets/services/icon_1.svg";
import icon_2 from "../../assets/services/icon_2.svg";
import icon_3 from "../../assets/services/icon_3.svg";

const Services = () => {
  return (
    <div className="services">
      <Row>
        <Col xs={12} md={12} lg={6} className="p-0">
          <div className="services-background">
            <img
              className="w-100 h-100"
              src={background}
              alt="background"
              loading="lazy"
            />
          </div>
        </Col>
        <Col xs={12} md={12} lg={6} className="p-0">
          <div className="services-benefits">
            <div className="services-icons">
              <div
                className="service-icon mt-3"
                style={{ marginBottom: "50px" }}
              >
                <div className="serv-icon">
                  <img src={icon_1} alt="icon" />
                </div>
                <div className="icon-details">
                  <h4>Save Money and Time</h4>
                  <p>
                    Staffing your restaurant can be both costly and
                    time-consuming. SmartServe offers a solution: an always-on
                    voice assistant that is familiar with your menu and ready to
                    handle incoming calls. By leveraging our innovative
                    technology, you can alleviate the need to continuously train
                    new employees and adjust schedules to ensure someone is
                    available to answer the phone.
                  </p>
                </div>
              </div>
              <div className="service-icon" style={{ marginBottom: "50px" }}>
                <div className="serv-icon">
                  <img src={icon_2} alt="icon" />
                </div>
                <div className="icon-details">
                  <h4>Eliminate Hold Time</h4>
                  <p>
                    Staffing your restaurant can be both costly and
                    time-consuming. SmartServe offers a solution: an always-on
                    voice assistant that is familiar with your menu and ready to
                    handle incoming calls. By leveraging our innovative
                    technology, you can alleviate the need to continuously train
                    new employees and adjust schedules to ensure someone is
                    available to answer the phone.
                  </p>
                </div>
              </div>
              <div className="service-icon">
                <div className="serv-icon">
                  <img src={icon_3} alt="icon" />
                </div>
                <div className="icon-details">
                  <h4>Enhance Customer Service</h4>
                  <p>
                    Busy and hungry customers calling ahead for take-out or with
                    inquiries rarely have the patience to be put on hold. That's
                    where our dedicated voice assistant steps in, providing
                    helpful service every time and freeing your staff to focus
                    on quality food preparation and courteous delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Services;
