import React from "react";
import { Col } from "react-bootstrap";
import upload from "../../assets/speechToText/speechAudio/tabler_upload.png";

const FileAudioBox = ({ audioFile, setAudioFile }) => {
  const handleFileChange = (event) => {
    setAudioFile({
      audio: event.target.files[0],
      type: "file",
    });
  };
  return (
    <Col xs={12} md={6} lg={4}>
      <div className="audio-box">
        <img src={upload} alt="audio" />
        <p className="text-center">Upload Audio File</p>
        <div className="input-group">
          <input
            onChange={handleFileChange}
            type="file"
            className="form-control mb-0 p-0"
            id="fileUpload"
          />
          <label
            className="input-group-text p-0 bg-dark text-white px-2"
            htmlFor="fileUpload"
          >
            Browse
          </label>
        </div>
      </div>
    </Col>
  );
};

export default FileAudioBox;
