import Modal from "react-modal";
import videoMp4 from "../../assets/products/demo.mp4";

// eslint-disable-next-line react/prop-types
const IframeModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Video Modal">
      <button className="close-iframe" onClick={onClose}>
        x
      </button>
      <iframe
        title="Video"
        width="100%"
        height="100%"
        src={videoMp4}
        allowFullScreen
      ></iframe>
    </Modal>
  );
};

export default IframeModal;
