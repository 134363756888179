import { Navigate } from "react-router-dom";

const PublicRoute = ({ redirectPath, children }) => {
  const retToken = localStorage.getItem("user_token");

  if (retToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PublicRoute;
