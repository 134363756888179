import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/products/logo.png";
import zigzag from "../../assets/products/vector.png";
import watch from "../../assets/products/demo.png";
import "./products.css";
import Heading from "../Heading/Heading";
import { useState } from "react";
import IframeModal from "../IframeModal/IframeModal";

const Products = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="products">
      <Container>
        <Heading logo={logo} title={"Products"} />
        <Row>
          <Col xs={12} md={6} lg={6}>
            <div className="smart-ordering mb-3">
              <h1>Smart ordering</h1>
              <h3>
                Simplifying Phone Orders with Intelligent Voice Technology
              </h3>
              <p>
                Experience the convenience of our user-friendly voice assistant
                designed to effortlessly handle phone orders for your
                restaurant. Our cutting-edge solution seamlessly integrates with
                leading POS systems, ensuring smooth and efficient order
                processing. And with ongoing development, more POS integrations
                are on the horizon, guaranteeing compatibility with your
                preferred system.
              </p>
              <img src={zigzag} alt="zigzag" className="zigzag" />
              <div className="watch-demo">
                <div className="watch-img-container">
                  <img onClick={openModal} src={watch} alt="watch-demo" />
                </div>
                <span>Watch DEMO</span>
                <IframeModal
                  VideoModal
                  isOpen={modalIsOpen}
                  onClose={closeModal}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="smart-answering">
              <div className="overlay">
                <h1>Smart Answering</h1>
                <h3>Revolutionizing Restaurant Communication</h3>
                <p>
                  Empower your restaurant with a personalized AI-driven voice
                  assistant that seamlessly manages every aspect of your phone
                  calls. Our innovative solution handles 100% of your incoming
                  calls, offering a friendly and efficient service to your
                  customers. From initial greetings to detailed inquiries, our
                  smart assistant is equipped to handle it all.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;
