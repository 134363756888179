import RestDashNav from "../RestDashNav/RestDashNav";
import "./addNewItem.css";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import SelectMenuComponent from "../SelectMenuComponent/SelectMenuComponent";
import { addItem, getCategories } from "../../utilies/api";
import { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../Pages/ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddItemImage from "../ChangeItemImage/AddItemImage";

const AddNewItem = () => {
  const token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  // store item image
  const [itemImg, setItemImg] = useState("");
  // input validation
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Regex pattern to match integers and floats
    const pattern = /^[+-]?\d*\.?\d*$/;

    if (pattern.test(value) || value === "") {
      setFormData({ ...formData, price: value });
    }
  };
  const navigate = useNavigate();
  // store selected category
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [availability, setAvailability] = useState("Select Availability");
  // add new item api fun
  // store inputs values
  const [formData, setFormData] = useState({
    english_name: "",
    arabic_name: "",
    arabic_ingredients: "",
    english_ingredients: "",
    price: "",
  });
  //set input values inside state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const addNewItem = async () => {
    try {
      let itemData = {
        menuId: localStorage.getItem("user_id"),
        categoryId: selectedCategory,
        availability: availability,
        image: itemImg,
        ...formData,
      };
      if (
        formData.english_name !== "" &&
        formData.arabic_name !== "" &&
        formData.english_ingredients !== "" &&
        formData.arabic_ingredients !== "" &&
        selectedCategory !== 0 &&
        formData.price > 0
      ) {
        const result = await addItem(itemData, token);
        notifySuccess(result.message);
        setTimeout(() => {
          navigate("/menuAndCategory/items");
        }, 2000);
      } else {
        notifyError("Please Enter All Required Fields");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  // store all categories api fun
  const [categories, setAllCategories] = useState([]);
  useEffect(() => {
    const getAllCategories = async (rest_token, rest_id) => {
      const { data } = await getCategories(rest_token, rest_id);
      setAllCategories(data);
    };
    getAllCategories(token, user_id);
  }, [selectedCategory, token, user_id]);

  return (
    <>
      <RestDashNav
        title={"Add New Item"}
        subTitle={"Create new item to your menu"}
        redirectPath={"/menuAndCategory/items"}
        navigate={navigate}
      />
      <div className="my-orders">
        <div className="table-container">
          <ToastContainer />

          <AddItemImage
            itemImg={itemImg}
            imgEndPoint={"menu"}
            setItemImg={setItemImg}
          />
          <Row>
            <Col xs={12} md={12} lg={6}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="exampleFormControlInput1"
              >
                English Item Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Item name"
                onChange={(e) => handleChange(e)}
                name="english_name"
                value={formData.english_name}
              />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="exampleFormControlInput1"
              >
                Arabic Item Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Item name"
                onChange={(e) => handleChange(e)}
                name="arabic_name"
                value={formData.arabic_name}
              />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="exampleFormControlInput3"
              >
                English Description
              </label>
              <textarea
                style={{
                  width: "100%",
                  height: "135px",
                  resize: "none",
                  marginBottom: "15px",
                }}
                type="text"
                className="form-control"
                id="exampleFormControlInput3"
                placeholder="Description"
                onChange={(e) => handleChange(e)}
                name="english_ingredients"
                value={formData.english_ingredients}
              />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="exampleFormControlInput3"
              >
                Arabic Description
              </label>
              <textarea
                style={{
                  width: "100%",
                  height: "135px",
                  resize: "none",
                  marginBottom: "15px",
                }}
                type="text"
                className="form-control"
                id="exampleFormControlInput3"
                placeholder="Description"
                onChange={(e) => handleChange(e)}
                name="arabic_ingredients"
                value={formData.arabic_ingredients}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="exampleFormControlInput1"
              >
                Category
              </label>
              <SelectMenuComponent
                categories={categories}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="exampleFormControlInput2"
              >
                Price
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="0"
                onChange={handleInputChange}
                name="price"
                value={formData.price}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Availability
              </label>
              <select
                className="form-select"
                onChange={(e) => setAvailability(e.target.value)}
                defaultValue={availability}
              >
                <option disabled>Select Availability</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </Col>
          </Row>
          <Row className="p-0">
            <Col md={6} lg={10} className="p-0"></Col>
            <Col md={6} lg={2} className="p-0">
              <button onClick={addNewItem} className="add-item-btn mb-5">
                Save
              </button>
            </Col>
          </Row>
        </div>
        <div className="mt-5 mb-3">
          <DashBoardFooter />
        </div>
      </div>
    </>
  );
};

export default AddNewItem;
