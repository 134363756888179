import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdAddPhotoAlternate } from "react-icons/md";
import { uploadItemPhoto } from "../../utilies/api";
import { notifyError, notifySuccess } from "../../Pages/ContactUs/ContactUs";

const AddItemImage = ({ itemImg, imgEndPoint, setItemImg }) => {
  const token = localStorage.getItem("user_token");
  const inputref = useRef(null);
  const [image, setImage] = useState("");
  const handleImgClick = () => {
    inputref?.current?.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };
  const handleImgSubmit = async (event) => {
    event.preventDefault();
    try {
      let formData = {
        image: image,
      };
      if (formData.image !== "") {
        const response = await uploadItemPhoto(imgEndPoint, formData, token);
        setItemImg(response.data.image);
        notifySuccess(response.message);
      } else {
        notifyError("Image is required");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <Row>
      <Col lg={12}>
        <div className="item-img">
          <div className="img-delete">
            <img
              className="edit"
              src={
                image
                  ? URL.createObjectURL(image)
                  : itemImg
                  ? itemImg
                  : "https://placehold.co/400"
              }
              alt="upload"
            />
            <MdAddPhotoAlternate
              onClick={handleImgClick}
              className="delete-icon-img"
              ref={inputref}
            />
          </div>
          <div className="upload-photo">
            <input
              type="file"
              ref={inputref}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <span onClick={handleImgSubmit} className="upload">
              Upload
            </span>{" "}
            <span className="drag">Your Menu Item Image</span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AddItemImage;
