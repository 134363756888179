import "./review.css";
// eslint-disable-next-line react/prop-types
const Review = ({ person_img }) => {
  return (
    <div className="review">
      <div className="img">
        <img src={person_img} alt="person" />
      </div>
      <div className="review-container">
        <div className="person-heading">
          <h3>Mina Tony,</h3>
          <h4>Manager of cafe.</h4>
        </div>
        <div className="person-details">
          <h1>AmeriBranch Cafe</h1>
          <p>
            "Our restaurant switched to SmartServe for ordering, and it's been a
            game-changer! The system is user-friendly, orders are processed
            swiftly, and the personalized recommendations add a delightful touch
            to our customers' experience."
          </p>
        </div>
        <div className="person-result">
          <div className="price">
            <h1>$ 50k</h1>
            <p>Increase in annual sales</p>
          </div>
          <div className="increase">
            <h1>50%</h1>
            <p>Increase in calls answering per day</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
