import { Accordion, Col, Container, Row } from "react-bootstrap";
import "./benefits.css";
import logo from "../../assets/main_logo_green.png";
import back_1 from "../../assets/Benefits/back_1.png";
import back_2 from "../../assets/Benefits/back_2.png";
import back_3 from "../../assets/Benefits/back_3.png";
import { useState } from "react";
import Heading from "../Heading/Heading";

const Benefits = () => {
  const [indx, setIndx] = useState(0);
  const arrImg = [back_1, back_2, back_3];
  return (
    <div className="benefits">
      <Container>
        <Heading logo={logo} title={"BENEFITS"} />
        <Row>
          <Col lg={6} className="mb-5">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0" onClick={() => setIndx(0)}>
                <Accordion.Header>Maximize Sales</Accordion.Header>
                <Accordion.Body>
                  SmartServe revolutionizes your restaurant's phone service by
                  ensuring every call is answered promptly, even during peak
                  hours. Say goodbye to busy signals and missed opportunities as
                  our intelligent voice assistant takes the reins, answering
                  inquiries, managing multiple orders simultaneously, and
                  seamlessly processing payments through your POS system.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" onClick={() => setIndx(1)}>
                <Accordion.Header>Take More Orders</Accordion.Header>
                <Accordion.Body>
                  While your dedicated staff focuses on cooking, cleaning, and
                  providing exceptional customer service, SmartServe's
                  intelligent voice assistant is hard at work on the phone,
                  answering inquiries and processing orders. With our innovative
                  solution, your restaurant can handle a higher volume of
                  orders, bringing added value to your business and enhancing
                  overall efficiency.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" onClick={() => setIndx(2)}>
                <Accordion.Header>Free Up Staff Time</Accordion.Header>
                <Accordion.Body>
                  Say goodbye to the time-consuming process of hiring, training,
                  and onboarding staff. SmartServe's voice assistant for
                  restaurants serves as a reliable and permanent team member,
                  always on shift and ready to handle phone calls, freeing up
                  your staff's valuable time and resources.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col lg={6}>
            <img className="w-100" src={arrImg[indx]} alt="back" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Benefits;
