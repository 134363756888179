import React, { useEffect, useState } from "react";
import "./profile.css";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import profile_user from "../../assets/RestDash/person.png";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import {
  GetRestaurantDetails,
  GetRestaurantUserDetails,
  updateRestData,
  updateRestUserData,
} from "../../utilies/api";
import { notifyError, notifySuccess } from "../ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import ChangeProfilePhoto from "../../Components/ChangeProfilePhoto/ChangeProfilePhoto";

const Profile = () => {
  const uerToken = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  const rest_id = localStorage.getItem("restUser_id");
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    address: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    const { target } = event;
    setProfile((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const handleChangePhone = (event) => {
    const newValue = event.target.value;

    // Remove any non-numeric characters
    const numericValue = newValue.replace(/\D/g, "");

    setProfile((prevState) => ({
      ...prevState,
      phone: numericValue,
    }));
  };
  // update profile
  const handleSubmitAndRedirect = async (e) => {
    e.preventDefault();
    const data = {
      id: +user_id ? user_id : rest_id,
      name: profile.name,
      phone: profile.phone,
      address: profile.address,
    };
    try {
      if (
        profile.name !== "" &&
        profile.phone !== "" &&
        profile.address !== ""
      ) {
        if (user_id) {
          const response = await updateRestData(data);
          notifySuccess(response.message);
          localStorage.setItem("userRecord", JSON.stringify(data));
          window.location.reload();
          setIsLoading(false);
        } else {
          const response = await updateRestUserData(data);
          notifySuccess(response.message);
          setIsLoading(false);
        }
      } else {
        notifyError("Please Fill All The Fields");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        if (user_id) {
          const { data } = await GetRestaurantDetails(user_id, uerToken);
          console.log(data);

          setProfile({
            name: data[0].name,
            email: data[0].email,
            phone: data[0].phone,
            password: data[0].password,
            address: data[0].address,
          });
          setIsLoading(false);
        } else {
          const { data } = await GetRestaurantUserDetails(rest_id);
          setProfile({
            name: data[0]?.name,
            email: data[0]?.email,
            phone: data[0]?.phone,
            password: data[0]?.password,
            address: data[0]?.address,
          });
          setIsLoading(false);
        }

        // setCompanyProfilePhoto(data.image);
      } catch (error) {
        console.error(error.response.data);
      }
    })();
  }, [rest_id, user_id, uerToken]);
  return (
    <>
      <RestDashNav
        title={"Profile Settings"}
        subTitle={"Update Your Profile"}
      />
      {isLoading ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="my-orders">
          <div className="table-container">
            <ToastContainer />
            <ChangeProfilePhoto
              id={localStorage.getItem("user_id")}
              companyProfilePhoto={profile_user}
              imgEndPoint={"stt/auth"}
            />
            <Row className="mb-4">
              <Col xs={12} md={6} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="fullName"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Full Name"
                  onChange={handleChange}
                  name="name"
                  value={profile.name}
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="address"
                >
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="address"
                  onChange={handleChange}
                  name="address"
                  value={profile.address}
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  readOnly={true}
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  onChange={handleChange}
                  name="email"
                  value={profile.email}
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onChange={handleChangePhone}
                  name="phone"
                  value={profile.phone}
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="password"
                >
                  Passsword
                </label>
                <input
                  readOnly={true}
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="********"
                  onChange={handleChange}
                  name="password"
                  value={profile.password}
                />
              </Col>
            </Row>
            <Row className="p-0">
              <Col md={6} lg={10} className="p-0"></Col>
              <Col md={6} lg={2} className="p-0 mb-5">
                <button
                  className="add-item-btn mb-5"
                  onClick={handleSubmitAndRedirect}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
          <div className="mt-5 mb-2">
            <DashBoardFooter />
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
