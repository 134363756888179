import { Navigate, useLocation } from "react-router-dom";
const SttProtectedRoute = ({ redirectPath, children }) => {
  // replace & state
  const token = localStorage.getItem("speech_token");
  const location = useLocation();
  if (!token) {
    return (
      <Navigate to={redirectPath} state={{ path: location.pathname }} replace />
    );
  } else {
    // check if the user token already exist
  }
  return children;
};

export default SttProtectedRoute;
