import React, { useEffect, useState } from "react";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import ChartBar from "./TokensChartBar";
import { useNavigate } from "react-router-dom";
import TokensChartBar from "./TokensChartBar";
// import { getRestReveneu } from "../../utilies/api";

const TokensChartPage = () => {
  const navigate = useNavigate();
  // const rest_id = localStorage.getItem("user_id");
  // const [revenue, setRevenue] = useState([]);

  // const getReveneu = async (rest_id) => {
  //   const response = await getRestReveneu(rest_id);
  //   setRevenue(response?.data);
  // };
  // useEffect(() => {
  //   getReveneu(rest_id);
  // }, [rest_id]);
  return (
    <>
      <RestDashNav
        title={"Tokens Overview"}
        subTitle={"Track your sales and export it."}
        redirectPath={"/dashboard"}
        navigate={navigate}
      />
      <div className="my-orders dashboard" style={{ height: "88vh" }}>
        <div className="d-flex align-items-center justify-content-end">
          {/* <select
            className="form-select border-1"
            style={{ width: "fit-content" }}
            aria-label="Default select example"
          >
            <option selected disabled>
              Select
            </option>
            <option value="7" selected>
              7 Days
            </option>
            <option value="15">15 Days</option>
            <option value="30">30 Days</option>
          </select> */}
        </div>
        <TokensChartBar />
      </div>
    </>
  );
};

export default TokensChartPage;
