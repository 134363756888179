import { FaArrowRightLong } from "react-icons/fa6";
import "./speechSteps.css";

// eslint-disable-next-line react/prop-types
const SpeechSteps = ({ title, desc, step_num, index, setIndex }) => {
  return (
    <div className="left-section">
      <h1>{title}</h1>
      <p>{desc}</p>
      <div className="steps-arrow">
        <span>{step_num}</span>
        <FaArrowRightLong
          onClick={() => {
            setIndex(index + 1);
            if (index >= 2) {
              setIndex(0);
            }
          }}
        />
      </div>
      <div className="steps-progress-container">
        <div
          className="steps-progress"
          style={
            index === 0
              ? { width: "25%" }
              : index === 1
              ? { width: "65%" }
              : index === 2
              ? { width: "100%" }
              : ""
          }
        ></div>
      </div>
    </div>
  );
};

export default SpeechSteps;
