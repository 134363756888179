import React, { useState } from "react";
import RestDashNav from "../../RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../../DashBoardFooter/DashBoardFooter";
import SelectMenuComponent from "../../SelectMenuComponent/SelectMenuComponent";
import "./speechSettings.css";
const SpeechSettings = () => {
  // toggle button
  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  return (
    <>
      <RestDashNav
        title={"Settings"}
        subTitle={"Manage your portal settings"}
        redirectPath={"/speech/signin"}
      />
      <div className="my-orders stt-settings">
        <div className="table-container table-responsive p-4">
          <Row>
            <Col lg={12}>
              <p className="mb-4">Transcription Settings</p>
              <label className="mb-3">Language Preferences</label>
              <SelectMenuComponent defaultValue={"Select"} />
              <label className="mt-4 mb-3">
                Billing and Subscription Settings
              </label>
              <br />
              <button className="add-payment">Add Payment Method</button>
              <div className="auto-renewal">
                <label>Auto-Renewal</label>
                {/* toggle button */}
                <div>
                  <label className="toggle-label">
                    <input
                      type="checkbox"
                      checked={isToggled}
                      onChange={handleToggle}
                    />
                    <div
                      style={
                        isToggled
                          ? { backgroundColor: "green" }
                          : { backgroundColor: "#ccc" }
                      }
                      className="toggle"
                    >
                      <div
                        className={`toggle-btn ${isToggled ? "on" : "off"}`}
                      ></div>
                    </div>
                    <div className="toggle-label-text">
                      {isToggled ? "Active" : "InActive"}
                    </div>
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={8} className="p-0"></Col>
            <Col md={6} lg={4} className="p-0 d-flex gap-4 mb-5">
              <div
                className="add-item-btn"
                style={{
                  backgroundColor: "rgba(187, 187, 187, 1)",
                  color: "white",
                }}
              >
                Cancel
              </div>
              <div className="add-item-btn">Save</div>
            </Col>
          </Row>
        </div>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default SpeechSettings;
