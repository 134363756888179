import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import mainLogo from "../../../assets/mainLogo.svg";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { notifyError, notifySuccess } from "../../../Pages/ContactUs/ContactUs";
import { speechUpdatePassword } from "../../../utilies/api";

const SpeechCreatePassword = () => {
  const navigate = useNavigate();
  const [UserObject, setUserObject] = useState({});
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    try {
      if (password === cPassword) {
        const response = await speechUpdatePassword({
          email: UserObject.email,
          password,
          cPassword,
        });
        notifySuccess(response.data.message);
        setPassword("");
        setCPassword("");
        setTimeout(() => {
          navigate("/speech/signin");
        }, 2500);
      } else {
        notifyError("Password And Confirm Password Not Matched");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("logger");
    if (accessToken) {
      const record = jwtDecode(accessToken);
      setUserObject(record);
    }
  }, []);
  return (
    <div className="signup speech-reset">
      <img
        onClick={() => navigate("/speech")}
        className="login-logo"
        src={mainLogo}
        alt="logo"
      />
      <Row className="w-100 m-auto">
        <ToastContainer />
        <Col lg={2} xl={3}></Col>
        <Col xs={12} md={12} lg={8} xl={6} className="p-0">
          <Container className="container-relative">
            <div className="side-form">
              <h1 className="mb-3">Create New Password</h1>
              <p className="mb-4">
                You've successfully verified your identity. Please enter a new
                password below to secure your account.
              </p>
              <form onSubmit={handleUpdatePass} className="form">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="email">
                      New Password
                    </label>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="false"
                      autoSave="false"
                      autoFocus={true}
                      required
                      className="input mb-3"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your new password"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="password">
                      Confirm Password
                    </label>
                    <input
                      value={cPassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                      className="input mb-3"
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Enter Your Confirm Password"
                    />
                  </Col>
                </Row>
                <input
                  className="login-button stt-login-btn mb-2 mt-3"
                  type="submit"
                  value="Send"
                />
                <div className="forget-link text-center mt-3">
                  <Link to="/speech/signin" className="forget">
                    Back To Login
                  </Link>
                </div>
              </form>
            </div>
            <p className="login-footer">© 2024 SmartServe </p>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default SpeechCreatePassword;
