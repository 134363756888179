import { NavLink } from "react-router-dom";

const UserLinks = () => {
  return (
    <ul className="user-links">
      <NavLink to="/">HOME</NavLink>
      {/* <NavLink to="/aboutMurad">ABOUT MURAD</NavLink> */}
      <NavLink to="/speech">SPEECH TO TEXT</NavLink>
      <NavLink to="/aboutUs">ABOUT US</NavLink>
      <NavLink to="/contactUs">CONTACT US</NavLink>
    </ul>
  );
};

export default UserLinks;
