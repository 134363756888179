import React from "react";
import "./notifications.css";
import RestDashNav from "../RestDashNav/RestDashNav";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import useNotifications from "../../hooks/useNotifications";

export function FormatDate({ dateString }) {
  // Use moment to parse and format the date
  const formattedDate = moment(dateString).format("DD MMM YYYY hh:mmA");
  return <span>{formattedDate}</span>;
}
const Notifications = () => {
  const navigate = useNavigate();
  const rest_id = localStorage.getItem("user_id");
  const { notifications, loading, error } = useNotifications(
    `https://wizard.smartserve.ai/restaurant/active/${rest_id}/notifications`
  );

  if (loading)
    return (
      <p className="fw-bold">
        <div className="spinner">
          <div className="loader"></div>
        </div>
      </p>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <RestDashNav
        title={"Notificarions"}
        subTitle={"Summary of your Restaurant Notifications."}
        redirectPath={"/dashboard"}
        navigate={navigate}
      />
      <div className="notifications-page">
        <div className="my-orders">
          <div className="table-container">
            {notifications?.map((notification) => (
              <div
                key={notification?.orderId}
                className="position-relative rounded mb-2"
                style={{
                  backgroundColor: "rgba(223, 231, 252, 0.6)",
                  padding: "10px",
                  minHeight: "100px",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Link
                    to={`/orderDetails/${notification?.Order?.id}`}
                    style={{
                      color: "#415FAC",
                      fontSize: "20px",
                      textDecoration: "underline",
                    }}
                    className="fw-bold mb-0"
                  >
                    #{notification?.Order?.id}
                  </Link>
                  <p
                    style={
                      notification?.Order?.orderType === "delivery"
                        ? { backgroundColor: "#415FAC" }
                        : { backgroundColor: "#129D54" }
                    }
                    className="order-notify-status"
                  >
                    {notification?.Order?.orderType}
                  </p>
                </div>
                {notification?.Order?.items.map((item) => (
                  <div>
                    <p
                      style={{ fontSize: "14px", fontWeight: "600" }}
                      className="text-secondary mb-0"
                    >
                      {item?.qty}x {item?.english_name}
                    </p>
                  </div>
                ))}
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  className="text-secondary mt-3 d-flex align-items-center justify-content-end"
                >
                  <FormatDate dateString={notification?.createdAt} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
