import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modalComp.css";

function DeleteUser({ show, handleClose, user, index, deleteUserFun }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body
          className="modalComp-body"
          id="contained-modal-title-vcenter"
        >
          <p className="heading">Are you sure to Delete this User?</p>
          <p className="sub-heading">Delete {user[index].name}</p>
        </Modal.Body>
        <Modal.Footer className="modalComp-footer">
          <Button variant="dark" onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteUserFun(user[index].id)}
          >
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteUser;
