import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import RestDashNav from "../RestDashNav/RestDashNav";
import { useEffect, useState } from "react";
import { editItem, getCategories, getItemDetails } from "../../utilies/api";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../Pages/ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import ChangeItemImage from "../ChangeItemImage/ChangeItemImage";
const EditItem = () => {
  const token = localStorage.getItem("user_token");
  const [isLoading, setIsLoading] = useState(true);
  // input validation
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Regex pattern to match integers and floats
    const pattern = /^[+-]?\d*\.?\d*$/;

    if (pattern.test(value) || value === "") {
      setFormData({ ...formData, price: value });
    }
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const resId = localStorage.getItem("user_id");
  // edit item api fun
  // store item image
  const [itemImg, setItemImg] = useState("");
  // store inputs values
  const [formData, setFormData] = useState({
    english_name: "",
    arabic_name: "",
    arabic_ingredients: "",
    english_ingredients: "",
    price: 0,
  });
  //set input values inside state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // store selected category
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [availability, setAvailability] = useState(null);
  // store all categories api fun
  const [categories, setAllCategories] = useState([]);
  // update item api fun
  const editItemFun = async () => {
    try {
      let itemData = {
        id: +id,
        availability,
        categoryId: selectedCategory,
        ...formData,
      };
      if (
        formData.english_name !== "" &&
        formData.arabic_name !== "" &&
        formData.english_ingredients !== "" &&
        formData.arabic_ingredients !== "" &&
        formData.price > 0
      ) {
        const result = await editItem(itemData);
        notifySuccess(result.message);
        setTimeout(() => {
          navigate("/menuAndCategory/items");
        }, 2000);
      } else {
        notifyError("Please Enter All Required Fields");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  const getAllCategories = async (rest_token) => {
    try {
      const response = await getCategories(rest_token);
      setAllCategories(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getItemData = async (id, resId, token) => {
    try {
      const { data } = await getItemDetails(id, resId, token);
      setFormData({
        english_name: data[0]?.english_name,
        arabic_name: data[0]?.arabic_name,
        arabic_ingredients: data[0]?.arabic_ingredients,
        english_ingredients: data[0]?.english_ingredients,
        price: data[0]?.price,
      });
      setAvailability(data[0]?.availability);
      setSelectedCategory(data[0]?.categoryId);
      setItemImg(data[0].image);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCategories(token);
    getItemData(id, resId, token);
  }, [id, resId, token]);

  return (
    <>
      <RestDashNav
        title={`Edit ${formData.english_name}`}
        subTitle={"Edit item"}
        navigate={navigate}
        redirectPath={"/menuAndCategory/items"}
      />
      <div className="my-orders">
        <div className="table-container">
          <ToastContainer />
          {isLoading ? (
            <div className="spinner">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <ChangeItemImage itemImg={itemImg} imgEndPoint={"menu"} />
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="exampleFormControlInput1"
                  >
                    English Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter Item name"
                    value={formData.english_name}
                    onChange={handleChange}
                    name="english_name"
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="exampleFormControlInput1"
                  >
                    Arabic Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter Item name"
                    value={formData.arabic_name}
                    onChange={handleChange}
                    name="arabic_name"
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="exampleFormControlInput3"
                  >
                    English Description
                  </label>
                  <textarea
                    style={{
                      width: "100%",
                      height: "135px",
                      resize: "none",
                      marginBottom: "15px",
                    }}
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput3"
                    placeholder="Description"
                    value={formData.english_ingredients}
                    onChange={handleChange}
                    name="english_ingredients"
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="exampleFormControlInput3"
                  >
                    Arabic Description
                  </label>
                  <textarea
                    style={{
                      width: "100%",
                      height: "135px",
                      resize: "none",
                      marginBottom: "15px",
                    }}
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput3"
                    placeholder="Description"
                    value={formData.arabic_ingredients}
                    onChange={handleChange}
                    name="arabic_ingredients"
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="exampleFormControlInput1"
                  >
                    Category
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    defaultValue={"selected"}
                  >
                    <option value="selected" disabled>
                      Select Category
                    </option>
                    {categories?.map((category) => (
                      <option
                        key={category.id}
                        value={category.id}
                        selected={category.id === selectedCategory}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="exampleFormControlInput2"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="0"
                    onChange={handleInputChange}
                    name="price"
                    value={formData.price}
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Availability
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => setAvailability(e.target.value)}
                    value={availability}
                    name="availability"
                  >
                    <option value="selected" disabled>
                      Select Availability
                    </option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </Col>
              </Row>
              <Row className="p-0">
                <Col md={6} lg={10} className="p-0"></Col>
                <Col md={6} lg={2} className="p-0">
                  <button
                    className="add-item-btn mb-5"
                    onClick={() => editItemFun()}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </>
          )}
        </div>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default EditItem;
