import { Col, Container } from "react-bootstrap";
import "./smartBrands.css";
import Slider from "react-slick";
import brand_1 from "../../assets/brands/brand_1.svg";
import brand_2 from "../../assets/brands/brand_2.svg";
import brand_3 from "../../assets/brands/brand_3.svg";
import brand_4 from "../../assets/brands/brand_4.svg";
import brand_5 from "../../assets/brands/brand_5.svg";
import brand_6 from "../../assets/brands/brand_6.svg";
import brand_7 from "../../assets/brands/brand_7.svg";
import brand_8 from "../../assets/brands/brand_8.svg";
import brand_9 from "../../assets/brands/brand_9.svg";
import brand_10 from "../../assets/brands/brand_10.svg";
import smile from "../../assets/brands/smile.svg";

const SmartBrands = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: false,
    slidesToShow: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rows: 2,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="brands">
      <Container>
        <div className="badge">We're here to assist you</div>
        <div className="brands-heading">
          Restaurant Brands Choose SmartServe
        </div>
        <div className="smile-face">
          <div className="badge">Hi, I would like to make an order.</div>
          <div className="badge">
            <img src={smile} alt="smile" />
          </div>
        </div>
        <p className="brands-desc">
          Restaurant brands worldwide are opting for SmartServe, the intelligent
          choice for seamless and efficient dining experiences.
        </p>
        <div>
          <Slider {...settings}>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_1} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_2} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_3} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_4} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_5} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_6} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_7} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_8} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_9} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_10} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_1} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_2} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_3} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_4} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_5} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_6} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_7} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_8} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_9} alt="brand" className="mb-0" />
              </div>
            </Col>
            <Col lg={3}>
              <div className="img-container">
                <img src={brand_10} alt="brand" className="mb-0" />
              </div>
            </Col>
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default SmartBrands;
