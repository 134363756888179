import { Col, Row } from "react-bootstrap";
import "./menuItems.css";
import ModalComp from "../ModalComp/ModalComp";
import { useCallback, useEffect, useState } from "react";
import deleteIocn from "../../assets/RestDash/menu/delete_icon.png";
import edit_icon from "../../assets/RestDash/menu/edit_icon.png";
import menu_1 from "../../assets/RestDash/menu/menu_1.png";
import { useNavigate, useParams } from "react-router-dom";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import {
  GetRestaurantCategories,
  GetRestaurantMenuItems,
  deleteRestaurantItem,
} from "../../utilies/api";

const MenuItems = () => {
  const { id } = useParams();
  const user_id = localStorage.getItem("user_id");
  const [token, setToken] = useState(false);
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(id);
  const [menu, setMenu] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemAvailability, setItemAvailability] = useState("");
  const menuSearch = true;
  const [isLoading, setIsLoading] = useState(true);
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const getMenuItems = useCallback(
    async (itemName, menuId, itemAvailability, categoryId, token, page) => {
      try {
        const result = await GetRestaurantMenuItems(
          itemName,
          menuId,
          itemAvailability,
          categoryId,
          token,
          page
        );
        setMenu(result.data);
        setCurrentPage(result.pagination.page);
        setTotalPages(result.pagination.totalPages);
        setHasNextPage(result.pagination.hasNextPage);
        setHasPreviousPage(result.pagination.hasPreviousPage);
        const { data } = await GetRestaurantCategories(user_id, "", "", token);
        setCategories(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [user_id]
  );
  const deleteMenuItem = async (id) => {
    try {
      await deleteRestaurantItem(id, token);
      getMenuItems(
        itemName,
        user_id,
        itemAvailability,
        selectedCategory,
        token
      );
      setShowId("");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    setToken(localStorage.getItem("user_token"));
    if (token) {
      getMenuItems(
        itemName,
        user_id,
        itemAvailability,
        selectedCategory,
        token,
        currentPage
      );
    }
  }, [
    getMenuItems,
    itemName,
    user_id,
    itemAvailability,
    selectedCategory,
    token,
    currentPage,
  ]);
  return (
    <div className="my-orders menu">
      <h1>Search & Filter</h1>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <select
            className="form-select"
            onChange={(e) => setSelectedCategory(e.target.value)}
            defaultValue={selectedCategory}
            value={selectedCategory}
          >
            <option disabled>Select Category</option>
            {menuSearch ? <option value="">All</option> : null}
            {categories?.map((category) => (
              <option key={category?.id} value={category?.id}>
                {category?.name}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <select
            className="form-select"
            defaultValue={"Availability"}
            onChange={(e) => setItemAvailability(e.target.value)}
          >
            <option value="Availability" disabled>
              Select Availability
            </option>
            <option value="">All</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col xs={0} md={8} lg={9} xl={10} xxl={10}></Col>
        <Col xs={12} md={4} lg={3} xl={2} xxl={2}>
          <div className="add-item-btn" onClick={() => navigate("/addNewItem")}>
            Add New Item
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <Row>
          <Col lg={12}>
            <div
              className="table-container table-responsive mt-4 text-nowrap text-capitalize"
              style={
                menu.length >= 10
                  ? { height: "auto" }
                  : {
                      height: "60vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }
              }
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Item ID</th>
                    <th scope="col">Category</th>
                    <th scope="col">Price</th>
                    <th scope="col">Availability</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {menu.length > 0 ? (
                    menu.map((item, idx) => (
                      <tr key={item.id}>
                        <th scope="row">
                          <div className="menu-item-container">
                            <img
                              height={35}
                              className="rounded-circle"
                              src={item.image ? item.image : menu_1}
                              alt="menu"
                            />
                            <div className="menu-details">
                              <h2 style={{ color: "#415FAC" }}>
                                {item.english_name}
                              </h2>
                              <span>
                                {item?.english_ingredients.slice(0, 30)}...
                              </span>
                            </div>
                          </div>
                        </th>
                        <td>
                          <p style={{ fontWeight: "bold" }}>{item.id}</p>
                        </td>
                        <td>
                          <p style={{ fontWeight: "bold" }}>
                            {item?.category?.name}
                          </p>
                        </td>
                        <td>
                          <span className="total-amount">{item.price} JOD</span>
                        </td>
                        <td>
                          {/* #37B34A */}
                          <span
                            style={
                              item.availability !== false
                                ? { backgroundColor: "#37B34A" }
                                : { backgroundColor: "#F15623" }
                            }
                            className="status"
                          >
                            {item.availability === true
                              ? "Available"
                              : "Not Available"}
                          </span>
                        </td>
                        <td>
                          <img
                            style={{ cursor: "pointer" }}
                            src={edit_icon}
                            alt="edit"
                            onClick={() => navigate(`/editItem/${item.id}`)}
                          />
                          <img
                            onClick={() => handleShow(idx)}
                            src={deleteIocn}
                            alt="icon"
                            style={{ cursor: "pointer" }}
                          />
                          <ModalComp
                            show={showId === idx && show}
                            handleClose={handleClose}
                            category={menu}
                            index={idx}
                            deleteCategory={deleteMenuItem}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p className="empty-users" style={{ height: "20vh" }}>
                      No Menu Items found
                    </p>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
          />
        </Row>
      )}

      <DashBoardFooter />
    </div>
  );
};

export default MenuItems;
