import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/main_logo_white.png";
import "./reviews.css";
import person_1 from "../../assets/reviews/person_1.svg";
import person_2 from "../../assets/reviews/person_2.svg";
import Review from "../Review/Review";
import Heading from "../Heading/Heading";

const Reviews = () => {
  return (
    <div className="reviews">
      <Container>
        <Row>
          <Col xs={12} md={6} lg={5}>
            <div className="people-say">
              <Heading logo={logo} title={"REVIEWS"} />
              <h1>What People Say</h1>
              <p>Discover What Others Are Saying About SmartServe</p>
            </div>
          </Col>
          <Col xs={12} md={6} lg={7}>
            <div className="review-scroll">
              <Review person_img={person_1} />
              <Review person_img={person_2} />
              <Review person_img={person_1} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reviews;
