import logo from "../../assets/side_logo.png";
import icon_1 from "../../assets/RestDash/sideMenuIcons/icon_1.png";
import icon_2 from "../../assets/RestDash/sideMenuIcons/icon_2.png";
import icon_3 from "../../assets/RestDash/sideMenuIcons/icon_3.png";
import icon_4 from "../../assets/RestDash/sideMenuIcons/icon_4.png";
import icon_5 from "../../assets/RestDash/sideMenuIcons/icon_5.png";
import icon_6 from "../../assets/RestDash/dashBoard/dollar.png";
import black_1 from "../../assets/RestDash/sideMenuIcons/black_1.png";
import black_2 from "../../assets/RestDash/sideMenuIcons/black_2.png";
import black_3 from "../../assets/RestDash/sideMenuIcons/black_3.png";
import black_4 from "../../assets/RestDash/sideMenuIcons/black_4.png";
import black_5 from "../../assets/RestDash/sideMenuIcons/black_5.png";
import { TbLogout2 } from "react-icons/tb";
import "./restDashSideMenu.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { FcSalesPerformance } from "react-icons/fc";

const RestDashSideMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  const [level, setlevel] = useState("");
  useEffect(() => {
    setlevel(jwtDecode(localStorage.getItem("user_token")).record.level);
  }, []);
  return (
    <div className="sideMenu">
      <img className="logo w-100 px-1" src={logo} alt="logo" />
      <ul>
        {level === "admin" ? (
          <NavLink to="dashBoard">
            <img
              src={pathname === "/dashBoard" ? black_1 : icon_1}
              alt="icon"
            />
          </NavLink>
        ) : null}
        {level === "admin" ? (
          <NavLink to="revenewChart">
            <FcSalesPerformance style={{ fontSize: "20px" }} />
          </NavLink>
        ) : null}
        <NavLink to="myOrders">
          <img src={pathname === "/myOrders" ? black_2 : icon_2} alt="icon" />
        </NavLink>
        {level === "admin" ? (
          <>
            <NavLink to="menuAndCategory/items">
              <img
                src={pathname === "/menuAndCategory/items" ? black_3 : icon_3}
                alt="icon"
              />
            </NavLink>
            <NavLink to="rolesAndusers">
              <img
                src={pathname === "/rolesAndusers" ? black_4 : icon_4}
                alt="icon"
              />
            </NavLink>
          </>
        ) : null}
        <NavLink to="settings">
          <img src={pathname === "/settings" ? black_5 : icon_5} alt="icon" />
        </NavLink>
        {/* <div className="logOut-icon" onClick={handleLogOut}>
          <img
            src={icon_7}
            alt="icon"
            style={{ transform: "rotate(180deg)" }}
          />
        </div> */}
        <TbLogout2
          onClick={handleLogOut}
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </ul>
    </div>
  );
};

export default RestDashSideMenu;
