import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SpeechDashSideMenu from "../Components/SpeechDashSideMenu/SpeechDashSideMenu";

const SpeechDashbaordLayout = () => {
  return (
    <>
      <ScrollToTop />
      <div>
        <Row>
          <Col xs={2} md={1} lg={1} className="p-0">
            <SpeechDashSideMenu />
          </Col>
          <Col xs={10} md={11} lg={11} className="p-0">
            <Outlet />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SpeechDashbaordLayout;
