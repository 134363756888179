import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { GetRestaurantUsers, updateRestaurantData } from "../../utilies/api";

const EditUser = ({ show, handleClose, user, setUsers, index }) => {
  const userToken = localStorage.getItem("user_token");
  const [succMsg, setSuccMsg] = useState("");
  const [userErrMsg, setUserErrMsg] = useState("");
  const [userDetails, setUserDetails] = useState({
    id: user[index].id,
    name: user[index].name,
    phone: user[index].phone,
    level: user[index].level,
    status: user[index].status,
  });
  // get profile inputs value
  const handleChange = (event) => {
    const { target } = event;
    setUserDetails((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const editUser = async (token) => {
    try {
      const result = await updateRestaurantData(userDetails, token);
      setSuccMsg(result.message);
      setUserErrMsg("");
      setTimeout(() => {
        handleClose();
        setSuccMsg("");
        setUserErrMsg("");
      }, 1500);
      const userResult = await GetRestaurantUsers(
        localStorage.getItem("user_id"),
        "",
        "",
        "",
        "",
        userToken
      );
      setUsers(userResult.data);
    } catch (error) {
      setUserErrMsg(error.response.data.message);
      setSuccMsg("");
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {userDetails.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userErrMsg ? (
          <div className="alert alert-danger mx-0" role="alert">
            {userErrMsg}
          </div>
        ) : null}
        {succMsg ? (
          <Alert className="alert alert-success mx-0">{succMsg}</Alert>
        ) : null}
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter user name"
              defaultValue={userDetails.name}
              name="name"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email address"
              defaultValue={user[index].email}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="number">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Mobile Number"
              defaultValue={userDetails.phone}
              name="phone"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="role">
            <Form.Label>Role</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={userDetails.level}
              name="level"
              onChange={handleChange}
            >
              <option disabled>Select</option>
              <option value="manager">Manager</option>
              <option value="employee">Employee</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="status">
            <Form.Label>status</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={userDetails?.status}
              name="status"
              onChange={handleChange}
            >
              <option disabled>Select</option>
              <option value={true}>Active</option>
              <option value={false}>In Active</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => editUser(userToken)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUser;
