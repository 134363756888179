import { Col, Container, Row } from "react-bootstrap";
import GetInTouchSection from "../../Components/GetInTouchSection/GetInTouchSection";
import Navbar from "../../Components/Navbar/Navbar";
import white_logo from "../../assets/aboutUs/white_logo.png";
import green_logo from "../../assets/main_logo_green.png";
import logo from "../../assets/main_logo_white.png";
import img_1 from "../../assets/aboutUs/img_1.png";
import img_2 from "../../assets/aboutUs/img_2.png";
import card_img from "../../assets/aboutUs/card_img.png";
import audio from "../../assets/aboutUs/audio.png";
import person from "../../assets/aboutUs/person.png";
import "./aboutUs.css";
import Heading from "../../Components/Heading/Heading";
import FeatureCard from "../../Components/FeatureCard/FeatureCard";
import Footer from "../../Components/Footer/Footer";
import ScrollToTop from "../../Components/ScrollToTop";

const AboutUs = () => {
  return (
    <>
      <ScrollToTop />
      <div className="about-us">
        <Navbar logo_icon={white_logo} />
        <Container>
          <Row className="about-section">
            <Col lg={10}>
              <GetInTouchSection
                heading_logo={logo}
                heading_title={"About us"}
                main_title={
                  "We set out to build a better way to invest at your restaurant ."
                }
                main_desc={
                  "At SmartServe, we are at the forefront of innovation, presenting groundbreaking solutions like Smart Ordering and Smart Answering. With an unwavering dedication to enhancing operational efficiency and streamlining processes, our mission is to elevate customer experiences by seamlessly integrating intelligent technologies into the heart of restaurant operations."
                }
                main_btn={"Get in touch"}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="story">
        <Container>
          <Row>
            <Col xs={12} md={7} lg={7}>
              <img className="story-img" src={img_1} alt="img" />
            </Col>
            <Col xs={12} md={5} lg={5}>
              <img className="story-img" src={img_2} alt="img" />
            </Col>
            <Col lg={12}>
              <Heading logo={green_logo} title={"Our Story"} />
              <p className="stoty-desc">
                At SmartServe, our journey began with a vision to revolutionize
                the dining experience. We set out to build a better way for you
                to invest your time at the restaurant. With a commitment to
                innovation, we've crafted cutting-edge solutions like Smart
                Ordering and Smart Answering, aiming to transform the way you
                engage with the culinary world. Our mission is to elevate every
                moment you invest in savoring delicious meals, ensuring
                seamless, efficient, and delightful experiences at every turn.
                Welcome to a new era of dining, where SmartServe takes your
                investment in enjoyment to unprecedented heights.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="numbers">
        <Container>
          <h1 className="numbers-heading">Numbers speak for me best of all </h1>
          <Row>
            <Col xs={12} md={6} lg={4} xl={3} xxl={3}>
              <div className="numbers-success">
                <FeatureCard
                  card_img={card_img}
                  card_title={"10M+"}
                  card_desc={"Successful Smart Ordering transactions"}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4} xl={3} xxl={3}>
              <div className="numbers-success improvment">
                <FeatureCard
                  card_img={audio}
                  card_title={"98%"}
                  card_desc={
                    "Improvement in customer interactions with Smart Answering"
                  }
                />
              </div>
            </Col>
            <Col xs={12} md={12} lg={4} xl={6} xxl={5}>
              <div className="operations">
                <FeatureCard
                  card_title={"1,000+"}
                  card_desc={
                    "Restaurant operations seamlessly integrated, enhancing efficiency with SmartServe."
                  }
                />
                <img src={person} alt="person" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
