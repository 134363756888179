import logo from "../../assets/mainLogo.svg";
import icon_1 from "../../assets/speechToText/sideMenu/icons_1.png";
import icon_2 from "../../assets/speechToText/sideMenu/icon_2.png";
import icon_3 from "../../assets/speechToText/sideMenu/icon_3.png";
import black_1 from "../../assets/speechToText/sideMenu/black_1.png";
import black_2 from "../../assets/speechToText/sideMenu/black_2.png";
import black_3 from "../../assets/speechToText/sideMenu/black_3.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";

const SpeechDashSideMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/speech/signin");
  };
  return (
    <div className="sideMenu">
      <img className="logo" src={logo} alt="logo" />
      <ul>
        <NavLink to="dashBoard">
          <img
            src={pathname === "/speechDashboard/dashBoard" ? black_1 : icon_1}
            alt="icon"
          />
        </NavLink>
        <NavLink to="AudioTranscripe">
          <img
            src={
              pathname === "/speechDashboard/AudioTranscripe" ? black_2 : icon_2
            }
            alt="icon"
          />
        </NavLink>
        <NavLink to="settings">
          <img
            src={pathname === "/speechDashboard/settings" ? black_3 : icon_3}
            alt="icon"
          />
        </NavLink>
        <TbLogout2
          onClick={handleLogOut}
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </ul>
    </div>
  );
};

export default SpeechDashSideMenu;
