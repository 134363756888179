import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA_R2XLwsfalaJAYaToYUsoLCzB7gh6drQ",
  authDomain: "smartserve-8be6e.firebaseapp.com",
  projectId: "smartserve-8be6e",
  storageBucket: "smartserve-8be6e.appspot.com",
  messagingSenderId: "946096773978",
  appId: "1:946096773978:web:ac77bc69beb0c14de6f163",
  measurementId: "G-G6B9L8J2H4",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
