import { Col, Row } from "react-bootstrap";
import "./categoryItems.css";
import edit from "../../assets/RestDash/category/edit.png";
import deleteIocn from "../../assets/RestDash/category/delete.png";
import cat_1 from "../../assets/RestDash/category/cat_1.png";
import { useNavigate } from "react-router-dom";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { useEffect, useState } from "react";
import ModalComp from "../ModalComp/ModalComp";
import {
  GetRestaurantCategories,
  deleteRestaurantCategory,
} from "../../utilies/api";
import { notifyError } from "../../Pages/ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";

const CategoryItems = () => {
  const restId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  const [categories, setCategories] = useState([]);
  const [catNum, setCatNum] = useState("");
  const [catName, setcatName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getCategories = async (restId, catName, catNum, token) => {
    try {
      const result = await GetRestaurantCategories(
        restId,
        catName,
        catNum,
        token
      );
      setCategories(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const deleteCategory = async (id) => {
    try {
      await deleteRestaurantCategory(id, token);
      getCategories(restId, catName, catNum, token);
      setShowId("");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifyError(error.response.data.message);
    }
  };
  useEffect(() => {
    setToken(localStorage.getItem("user_token"));
    if (token) {
      getCategories(restId, catName, catNum, token);
    }
  }, [restId, catName, catNum, token]);
  return (
    <div className="my-orders mb-2">
      <ToastContainer />
      <h1 className="mb-3">Search & Filter</h1>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Category Name"
            value={catName}
            onChange={(e) => setcatName(e.target.value)}
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <input
            type="number"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Number of items"
            value={catNum}
            onChange={(e) => setCatNum(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={0} md={8} lg={9} xl={10} xxl={10}></Col>
        <Col xs={12} md={4} lg={3} xl={2} xxl={2}>
          <div
            className="add-item-btn mb-4"
            onClick={() => navigate("/addCategory")}
          >
            Add New Category
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <Row className="w-100 m-auto">
          {categories.length > 0 ? (
            categories.map((item, idx) => (
              <Col xs={12} md={6} lg={4} xl={3} xxl={3} key={item.id}>
                <div className="cat-info">
                  <div className="cat-top mb-4">
                    <p
                      className="category-name"
                      style={{ textTransform: "capitalize" }}
                      onClick={() =>
                        navigate(`/menuAndCategory/items/${item.id}`)
                      }
                    >
                      {item.name}
                    </p>
                    <div className="cat-icons">
                      <img
                        src={edit}
                        alt="icon"
                        onClick={() => navigate(`/editCategory/${item.id}`)}
                      />
                      <img
                        onClick={() => handleShow(idx)}
                        src={deleteIocn}
                        alt="icon"
                      />
                      <ModalComp
                        show={showId === idx && show}
                        handleClose={handleClose}
                        category={categories}
                        index={idx}
                        deleteCategory={deleteCategory}
                      />
                    </div>
                  </div>
                  <div className="cat-bottom">
                    <p>{item.menuItems?.length} items</p>
                    <div className="cat-imgs">
                      {item?.menuItems?.slice(0, 5)?.map((img) => {
                        return (
                          <img
                            key={img.id}
                            style={{ width: "28px", height: "28px" }}
                            src={cat_1}
                            alt="img"
                          />
                        );
                      })}
                      {item?.menuItems?.length > 5 ? (
                        <span>
                          {item?.menuItems?.length > 5
                            ? `+ ${item?.menuItems?.length - 5}`
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <p className="empty-users" style={{ height: "50vh" }}>
              No Categories found
            </p>
          )}
        </Row>
      )}
      <Row>
        <PaginationComponent />
      </Row>
      <DashBoardFooter />
    </div>
  );
};

export default CategoryItems;
