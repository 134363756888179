const data = [
    {
      question:"How can I customize my business’ voice assistant?",
      answer:"Customizing your business' voice assistant with Smart Ordering or Smart Answering provides a unique opportunity to tailor the experience to match your brand and meet the specific needs of your customers. Here's how you can customize your voice assistant",
      advs:{
        lists :["Greetings and Goodbyes","Business Description and Value Proposition","Product and Service Information","Menus","Frequently Asked Questions","Reservations/Appointments","Pricing","Ordering","Promotions and Deals","Policies","Parking and Accessibility","Wait Times"]
      }
    },
    {
      question: "Do I need to set up a new phone number, or can I use my current business line?",
      answer:"You can utilize your current business phone number seamlessly. Our system integrates with your existing line, eliminating the need for a new number. All you have to do is set up call forwarding, and our service will efficiently manage your incoming calls, ensuring a smooth transition without any disruptions to your current setup."
    },
    {
      question: "Can SmartServe handle multiple calls at the same time?",
      answer:"Absolutely. Our platform is designed to efficiently manage a large volume of calls simultaneously, ensuring that your customers never experience long wait times or delays. With SmartServe, you can rest assured that all incoming calls will be promptly and professionally handled, maximizing customer satisfaction and efficiency."
    },
    {
      question: "What if a customer wants to speak to a real person?",
      answer: "Absolutely, our system provides the flexibility to transfer customers to a real person whenever necessary. Whether it's for a more complex inquiry or a specific request, our voice assistant seamlessly facilitates the transfer, ensuring that your customers receive the personalized attention they need."
    },
    {
      question: "What technology does SmartServe Smart Answering technology use?",
      answer:"SmartServe utilizes the industry-leading independent voice AI platform, equipped with a comprehensive suite of voice AI technologies. This includes Automatic Speech Recognition (ASR), Natural Language Understanding (NLU), and Text to Speech (TTS). Additionally, SmartServe leverages cutting-edge generative AI technology, enabling businesses to swiftly develop customized voice assistants capable of accurately responding to a wide range of customer inquiries. With genAI, SmartServe ensures advanced capabilities and unparalleled accuracy in customer interactions."
    },
    {
      question:"How much does it cost?",
      answer:"For detailed pricing information tailored to your specific needs, please contact us via the link provided. A dedicated sales representative will reach out to you shortly to discuss your requirements and provide you with a personalized quote. We look forward to assisting you!"
    }
  ];
  
  export default data;
  