import "./industryCard.css";

// eslint-disable-next-line react/prop-types
const IndustryCard = ({ card_img, card_title, card_desc }) => {
  return (
    <div>
      <div className="icon-card">
        <div className="icon">
          <img src={card_img} alt="icon" />
        </div>
        <p className="title">{card_title}</p>
        <p className="text">{card_desc}</p>
      </div>
    </div>
  );
};

export default IndustryCard;
