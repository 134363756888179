/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import Heading from "../Heading/Heading";
import "./getInTouchSection.css";

const GetInTouchSection = ({
  heading_logo,
  heading_title,
  main_title,
  main_desc,
  main_btn,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hero-details mb-5">
        <Heading logo={heading_logo} title={heading_title} />
        <h1>{main_title}</h1>
        {main_desc ? <p>{main_desc}</p> : ""}
        {main_btn ? (
          <button onClick={() => navigate("/contactUs")} className="hero-btn">
            {main_btn}
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default GetInTouchSection;
