import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import mainLogo from "../../assets//mainLogo.svg";
import login_img from "../../assets/forms/forget-img.png";
import "./forgetPassword.css";
import { useState } from "react";
import { userResetPassword } from "../../utilies/api";
import { notifyError, notifySuccess } from "../ContactUs/ContactUs";
import { ToastContainer } from "react-toastify";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isRestaurant, setIsRestaurant] = useState(false);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await userResetPassword({
        email,
        isRestaurant: isRestaurant,
      });
      notifySuccess(response.data.message);
      setEmail("");
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <div className="login forget">
      <Row className="w-100 m-auto">
        <ToastContainer />
        <Col xs={12} md={12} lg={7} className="p-0">
          <Container className="container-relative">
            <img
              className="login-logo"
              src={mainLogo}
              alt="logo"
              onClick={() => navigate("/")}
            />
            <div className="side-form">
              <h1 className="mb-3">Forget Password</h1>
              <p className="mb-5">
                Enter your registered email address below, and we'll send you a
                link to reset your password.
              </p>
              <form onSubmit={handleResetPassword} className="form">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <label className="mb-2" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      className="input mb-2"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email Address"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div
                      className="form-check my-3"
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                        id="flexCheckDefault"
                        onChange={(e) => setIsRestaurant(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Is Restaurant ?
                      </label>
                    </div>
                  </Col>
                </Row>
                <button className="login-rest-btn mb-3">Send</button>
                <div className="forget-link" style={{ textAlign: "center" }}>
                  <Link to="/login" className="forget">
                    Back to login
                  </Link>
                </div>
              </form>
            </div>
            <p className="login-footer">© 2024 SmartServe </p>
          </Container>
        </Col>
        <Col xs={0} md={0} lg={5} className="p-0">
          <div className="side-login forget-section">
            <div className="side-content">
              <img src={login_img} alt="img" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgetPassword;
