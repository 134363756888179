import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import RootLayOut from "../LayOut/RootLayOut";
import HomePage from "../Pages/HomePage/HomePage";
import ContactUs from "../Pages/ContactUs/ContactUs";
import AboutUs from "../Pages/AboutUs/AboutUs";
import SpeechToText from "../Pages/SpeechToText/SpeechToText";
import Login from "../Pages/Login/Login";
import ForgetPassword from "../Pages/ForgetPassword/ForgetPassword";
import CreatePassword from "../Pages/CreatePassword/CreatePassword";
import RestDashboard from "../LayOut/RestDashboard";
import MyOrders from "../Components/MyOrders/MyOrders";
import OrderItemDetails from "../Components/OrderItemDetails/OrderItemDetails";
import MenuAndCategoriesPage from "../Pages/MenuAndCategoriesPage/MenuAndCategoriesPage";
import AddCategory from "../Components/AddCategory/AddCategory";
import EditCategory from "../Components/EditCategory/EditCategory";
import DashBoard from "../Pages/DashBoard/DashBoard";
import Profile from "../Pages/Profile/Profile";
import SpeechAuthLayOut from "../LayOut/SpeechAuthLayOut";
import SignUp from "../Components/SpeechToText/SignUp/SignUp";
import SignIn from "../Components/SpeechToText/SignIn/SignIn";
import ResetPassword from "../Components/SpeechToText/ResetPassword/ResetPassword";
import SpeechCreatePassword from "../Components/SpeechToText/SpeechCreatePassword/SpeechCreatePassword";
import AuthLayOut from "../LayOut/AuthLayOut";
import SpeechDashbaordLayout from "../LayOut/SpeechDashbaordLayout";
import SpeechDashbaord from "../Components/SpeechToText/SpeechDashbaord/SpeechDashbaord";
import SpeechAudio from "../Components/SpeechToText/SpeechAudio/SpeechAudio";
import SpeechSettings from "../Components/SpeechToText/SpeechSettings/SpeechSettings";
import ProtectedRoute from "../Components/auth/ProtectedRoute";
import SttProfile from "../Pages/SttProfile/SttProfile";
import AddNewItem from "../Components/AddNewItem/AddNewItem";
import EditItem from "../Components/EditItem/EditItem";
import RolesAndUsers from "../Pages/RolesAndUsers/RolesAndUsers";
import ErrorRouteHandler from "../Components/errors/ErrorRouteHandler";
import SttProtectedRoute from "../Components/auth/SttProtectedRoute";
import RevenewChartPage from "../Pages/RevenewChartPage/RevenewChartPage";
import Notifications from "../Components/Notifications/Notifications";
import MenuItems from "../Components/MenuItems/MenuItems";
import CategoryItems from "../Components/CategoryItems/CategoryItems";
import PublicRoute from "../Components/auth/PublicRoute";
import TokensChartPage from "../Pages/RevenewChartPage/TokensChartPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayOut />}>
        <Route index element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="speech" element={<SpeechToText />} />
        <Route path="contactUs" element={<ContactUs />} />
      </Route>
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/" element={<AuthLayOut />}>
        <Route
          path="login"
          element={
            <PublicRoute redirectPath="/dashBoard">
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="forgetPassword"
          element={
            <PublicRoute redirectPath="/dashBoard">
              <ForgetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="createPassword"
          element={
            <PublicRoute redirectPath="/dashBoard">
              <CreatePassword />
            </PublicRoute>
          }
        />
      </Route>
      <Route
        path="/"
        element={
          <ProtectedRoute redirectPath="/login">
            <RestDashboard />
          </ProtectedRoute>
        }
        errorElement={<ErrorRouteHandler />}
      >
        <Route
          path="dashBoard"
          element={
            <ProtectedRoute redirectPath="/login">
              <DashBoard />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="revenewChart"
          element={
            <ProtectedRoute redirectPath="/login">
              <RevenewChartPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="tokens-chart"
          element={
            <ProtectedRoute redirectPath="/login">
              <TokensChartPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="notifications"
          element={
            <ProtectedRoute redirectPath="/login">
              <Notifications />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="myOrders"
          element={
            <ProtectedRoute redirectPath="/login">
              <MyOrders />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="orderDetails/:id"
          element={
            <ProtectedRoute redirectPath="/login">
              <OrderItemDetails />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="menuAndCategory"
          element={
            <ProtectedRoute redirectPath="/login">
              <MenuAndCategoriesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        >
          <Route path="items/:id?" element={<MenuItems />} />
          <Route path="categories" element={<CategoryItems />} />
        </Route>
        <Route
          path="addCategory"
          element={
            <ProtectedRoute redirectPath="/login">
              <AddCategory />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="editCategory/:id"
          element={
            <ProtectedRoute redirectPath="/login">
              <EditCategory />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="addNewItem"
          element={
            <ProtectedRoute redirectPath="/login">
              <AddNewItem />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="editItem/:id"
          element={
            <ProtectedRoute redirectPath="/login">
              <EditItem />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        {/* <Route path="profile" element={<Profile />} /> */}
        <Route
          path="rolesAndusers"
          element={
            <ProtectedRoute redirectPath="/login">
              <RolesAndUsers />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute redirectPath="/login">
              <Profile />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
      </Route>
      <Route path="/speech" element={<SpeechAuthLayOut />}>
        <Route path="signup" element={<SignUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="resetPassword" element={<ResetPassword />} />
        <Route path="createPassword" element={<SpeechCreatePassword />} />
      </Route>
      <Route
        path="/speechDashboard"
        element={
          <SttProtectedRoute redirectPath={"/speech/signin"}>
            <SpeechDashbaordLayout />
          </SttProtectedRoute>
        }
        errorElement={<ErrorRouteHandler />}
      >
        <Route
          path="dashboard"
          element={
            <SttProtectedRoute redirectPath={"/speech/signin"}>
              <SpeechDashbaord />
            </SttProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="AudioTranscripe"
          element={
            <SttProtectedRoute redirectPath={"/speech/signin"}>
              <SpeechAudio />
            </SttProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="settings"
          element={
            <SttProtectedRoute redirectPath={"/speech/signin"}>
              <SpeechSettings />
            </SttProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="Profile"
          element={
            <SttProtectedRoute redirectPath={"/speech/signin"}>
              <SttProfile />
            </SttProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
      </Route>
    </>
  )
);

export default router;
